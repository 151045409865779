.banner
  position: relative
  &__video
    width: 100%
    min-height: 100vh
    object-fit: cover
    +screen('max', 1023)
      min-height: 860px
    +screen('max', 767)
      min-height: 85vh
  &__icon
    margin: 10rem 0 12rem
    position: relative
    z-index: 3
    +screen('max', 1200)
      margin: 7rem 0
    +screen('max', 1023)
      margin-bottom: clamp(7rem, 30vh, 240px)
    +screen('max', 767)
      width: 100%
      margin-top: 5rem
      margin-bottom: clamp(2rem, 10vh, 240px)
  &__title
    font-weight: 400
    font-size: clamp(39px, 12vw, 190px)
    text-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25)
    line-height: 0.85
    position: relative
    z-index: 3
    span,b
      background: linear-gradient(90deg, #fff 63.02%, rgba(255, 255, 255, 0.26) 100%)
      background-clip: text
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      display: inline-block
  &__slide
    position: relative
    z-index: 1
  &__inner
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding-top: 11rem
    box-sizing: border-box
    z-index: 2
  .slick-arrow
    position: absolute
    bottom: 8rem
    z-index: 3
  .slick-prev
    left: calc((100% - 1440px) / 2)
    +screen('max', 1440)
      left: 2rem
  .slick-next
    right: calc((100% - 1440px) / 2)
    +screen('max', 1440)
      right: 2rem
  .slick-slide
    height: auto
  .slick-dots
    position: absolute
    bottom: 9rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    gap: 2rem
    +screen('max', 767)
      display: none !important
    button
      font-weight: 500
      color: var(--base-cristal-white)
      +fs(16px)
    li
      position: relative
      &.slick-active
        &:before
          content: ''
          width: 20px
          height: 20px
          position: absolute
          top: 50%
          left: 50%
          translate: -50% -40%
          background: var(--base-cristal-white)
          border-radius: 100%
          z-index: 2
          transition: .5s ease