.btn
  display: inline-block
  border: 1px solid var(--base-rich-black)
  border-radius: 50px
  padding: 1.3rem 2.4rem
  box-sizing: border-box
  font-weight: 500
  line-height: 1.5
  +fs(16px)
  cursor: pointer
  position: relative
  z-index: 1
  &_play
    display: inline-flex
    align-items: center
    gap: 2rem
    transition: .5s ease
    &:hover
      border-color: var(--brandbook-main)
      color: var(--brandbook-main)
      img
        filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%)
  &_reg
    background: var(--brandbook-main)
    color: var(--base-cristal-white)
    width: 334px
    gap: 2rem
    display: inline-flex
    align-items: center
    justify-content: center
    +fs(18px)
    border-color: transparent
    font-weight: 600
    padding: 2.2rem 1rem
    transition: .5s ease
    &:before
      content: ''
      width: 100%
      height: 100%
      border-radius: 50px
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(360deg, #73b61c 0%, #558813 100%)
      z-index: -1
      opacity: 0
      visibility: hidden
      transition: .5s ease
    &:hover
      &:before
        opacity: 1
        visibility: visible
  &_white
    color: var(--base-cristal-white)
    border-color: var(--base-cristal-white)
  &_red
    background: rgba(242, 42, 82, 0.2)
    border-color: transparent
    color: var(--brandbook-more)
  &_black
    background: var(--base-rich-black)
    color: var(--base-cristal-white)
  &_transparent-green
    border-color: var(--brandbook-main)
    color: var(--brandbook-main)
    padding: .7rem 2rem
    +fs(14px)
  &__icon
    width: 16px
    height: 16px

.slick-arrow
  border: 1px solid rgba(255, 255, 255, 0.3)
  border-radius: 50px
  padding: 2.4rem
  box-sizing: border-box
  transition: .5s ease
  &:hover
    border-color: var(--base-cristal-white)