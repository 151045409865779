.places
  background: linear-gradient(135deg, #9fa6b5 0%, #2a2e35 100%)
  padding: 5rem 0
  position: relative
  +screen('max', 767)
    padding-top: 2rem
  &:before
    content: ''
    width: 100vw
    height: 65vw
    position: absolute
    bottom: -35%
    left: 50%
    transform: translateX(-50%)
    background-image: url(../icon/wave-4.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: 100%
    +screen('max', 1023)
      width: 150vw
      height: 100vw
    +screen('max', 767)
      bottom: 30%
      background-size: 150%
      height: 100%
      background-position: 100%
  &__inner
    position: relative
    z-index: 2
  &__top
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 5rem
    +screen('max', 1100)
      flex-wrap: wrap
    +screen('max', 1023)
      gap: 3rem
  &__txt
    font-family: var(--font-secondary)
    font-style: italic
    font-weight: 700
    font-size: min(238px, 15vw)
    line-height: .8
    -webkit-text-stroke: 2px #d3caca
    color: transparent
    position: relative
    flex-grow: 1
    +screen('max', 1023)
      font-size: 20vw
    +screen('max', 767)
      padding-top: 60px
    &:before
      content: attr(data-sign)
      font-size: min(24px, 1.5vw)
      color: #d3caca
      position: absolute
      top: 0
      left: 39%
      font-weight: 300
      font-family: var(--font-primary)
      word-spacing: 10px
      +screen('max', 1100)
        left: 28%
      +screen('max', 1023)
        font-size: 16px
        left: 38%
      +screen('max', 767)
        display: flex
        flex-flow: column
        width: 165px
  &__vnts
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 2rem 7rem
    +screen('max', 1100)
      grid-template-columns: repeat(4, 1fr)
      gap: 2rem
    +screen('max', 767)
      grid-template-columns: repeat(2, 1fr)
      gap: 1rem
  &__vnt
    text-align: center
    &:hover
      .places__icon
        filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%)
      .places__sign
        color: var(--brandbook-main)
  &__icon
    width: 41px
    height: 41px
  &__sign
    margin-top: 2rem
    font-weight: 600
    +fs(18px)
    color: #d3caca
    +screen('max', 1200)
      +fs(16px)
  &__caption
    color: var(--base-cristal-white)
    +fs(40px)
    font-weight: 700
    line-height: 0.8
    margin: 3.5rem 0
    +screen('max', 1023)
      +fs(30px)
      text-align: center
    +screen('max', 767)
      +fs(26px)
  &__shops
    display: flex
    flex-wrap: wrap
    gap: 2rem
  &__link
    position: relative
    width: calc(25% - 27px)
    padding-bottom: 15px
    +screen('max', 1023)
      width: calc(50% - 19px)
    +screen('max', 767)
      width: 100%
    &:before
      content: ''
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      width: 86px
      height: 15px
      background: url(../icon/icon-stars.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: contain
    svg
      width: 100%
    &:hover
      .oz-bg
        fill: #2358f3
      .oz-txt, .wb-text, .ya-over, .mm-txt
        fill: var(--base-cristal-white)
      .wb-bg
        fill: url(#wb-hover)
      .ya-bg
        fill: #fa3f1d
      .ya-letter
        fill: #fa3f1d
      .mm-bg
        fill: #9553d1
.footer
  background: var(--base-rich-black)
  padding: 14rem 0 9rem
  +screen('max', 1023)
    padding: 5rem 0
    position: relative
    z-index: 3
  &__inner
    display: flex
    justify-content: space-between
    gap: 2rem
    +screen('max', 1023)
      flex-wrap: wrap
  &__nav
    display: flex
    gap: min(215px, 10vw)
    +screen('max', 767)
      display: block
  &__home
    margin-top: -2rem
    +screen('max', 1023)
      flex-grow: 1
      width: 100%
      margin-bottom: 5rem
    +screen('max', 767)
      margin-bottom: 0
  &__list
    position: relative
    +screen('max', 767)
      margin-top: 7rem
    &:before
      content: attr(data-title)
      color: #4E5661
      position: absolute
      top: -45px
      left: 0
      font-weight: 500
      +fs(14px)
      +screen('max', 767)
        top: -35px
  &__item
    margin-bottom: 2.5rem
  &__link
    color: var(--base-cristal-white)
    font-weight: 500
    transition: .5s ease
    &:hover
      color: var(--brandbook-main)
  &__contacts
    position: relative
    color: var(--base-cristal-white)
    max-width: 215px
    +screen('max', 767)
      margin-left: 10vw
      margin-top: 30px
  &__caption
    color: #4E5661
    position: absolute
    top: -45px
    left: 0
    font-weight: 500
    +fs(14px)
    +screen('max', 767)
      left: -30px
      top: -35px
  &__sign
    +fs(12px)
    margin-bottom: 1rem
  &__phone
    +fs(28px)
    font-weight: 600
    position: relative
    gap: 1rem
    margin-bottom: 2.5rem
    display: block
    transition: .5s ease
    svg
      width: 26px
      height: 26px
      color: #4E5661
      position: absolute
      left: -35px
      top: 5px
    &:hover
      color: var(--brandbook-main)
  &__address
    +fs(14px)
    font-weight: 600
    position: relative
    gap: 1rem
    margin-bottom: 2.5rem
    display: block
    svg
      position: absolute
      left: -30px
      top: 5px
      width: 14px
      height: 16px
      color: #4E5661
      +screen('max', 767)
        width: 18px
        height: 18px
  &__social
    display: flex
    justify-content: flex-end
    gap: 1.4rem
  &__soc-link
    border-radius: 30px
    backdrop-filter: blur(20px)
    background: linear-gradient(107.01deg, rgba(0, 0, 0, 0.41) 30.416%,rgba(0, 0, 0, 0.29) 101.259%)
    padding: 1.4rem
    box-sizing: border-box
    transition: .5s ease
    &:hover
      background: var(--buttons-white)
  &__icon
    width: 18px
    height: 18px

.copy
  background: var(--base-rich-black)
  padding-bottom: 4.5rem
  color: #4E5661
  +fs(14px)
  font-weight: 500
  &__inner
    display: flex
    justify-content: space-between
    align-items: center
    +screen('max', 767)
      flex-wrap: wrap
      gap: 3rem
  &__link
    img
      margin-left: 1rem
  &__pic
    +screen('max', 767)
      order: -1
      width: 100%