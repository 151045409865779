.header
  position: fixed
  width: 100%
  z-index: 99
  top: 0
  +screen('max', 767)
    padding: 2rem 0
  &:before
    content: ''
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    z-index: -1
    backdrop-filter: blur(56px)
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)
    background: linear-gradient(142deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.15) 100%)
    opacity: 0.8
  &__inner
    display: flex
    justify-content: space-between
    align-items: center
    gap: 3rem
    position: relative
    +screen('max', 1200)
      padding: 0 2rem
  &__home
    +screen('max', 1023)
      position: absolute
      top: 10px
      left: 20px
    +screen('max', 767)
      left: clamp(30px, 50vw, 200px)
      top: 0
  &__pic
    +screen('max', 1400)
      width: 180px
    +screen('max', 1023)
      width: 135px
    +screen('max', 767)
      width: 85px
  &__info
    flex-grow: 1
    +screen('max', 767)
      flex-grow: 0
  &__top
    padding-block: 1.9rem
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 2rem
    position: relative
    +screen('max', 1023)
      margin-left: 120px
      padding-left: 30px
    +screen('max', 767)
      display: none
    &:before
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100vw
      height: 1px
      background: var(--base-cristal-white)
      opacity: 0.2
  &__search
    width: 50%
    display: flex
    align-items: center
    +screen('max', 1023)
      width: 33%
    svg
      width: 15px
      height: 15px
      color: var(--base-cristal-white)
      flex-shrink: 0
  &__favourites, &__lk
    svg
      transition: .5s ease
      &:hover
        color: var(--brandbook-main)
  &__field
    +fs(16px)
    font-weight: 500
    background: none
    margin-left: 1rem
    color: var(--base-cristal-white)
    &::placeholder
      color: var(--base-cristal-white)
  &__contacts
    display: flex
    justify-content: flex-end
    align-content: center
    gap: 5rem
    +screen('max', 1023)
      gap: 1rem
  &__city
    display: flex
    align-items: center
    gap: 1rem
    flex-shrink: 0
    svg
      width: 18px
      height: 18px
      color: var(--base-cristal-white)
      opacity: 0.3
  &__city-select
    background: none
    border: none
    color: var(--base-cristal-white)
    +fs(16px)
    font-weight: 500
    font-family: var(--font-primary)
  &__phone
    font-weight: 500
    display: flex
    align-items: center
    gap: 1rem
    color: var(--base-cristal-white)
    flex-shrink: 0
    transition: .5s ease
    &:hover
      color: var(--brandbook-main)
    svg
      width: 18px
      height: 18px
      opacity: 0.3
  &__bottom
    display: flex
    justify-content: flex-end
    gap: 3rem
    padding-right: 3rem
    box-sizing: border-box
    +screen('max', 1400)
      padding-right: 0
    +screen('max', 1100)
      justify-content: space-between
  &__control
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 3rem
    svg
      color: var(--base-cristal-white)
      width: 18px
      height: 18px
  &__basket
    &.elms
      position: relative
      &:before
        content: ''
        width: 6px
        height: 6px
        position: absolute
        top: 0
        right: -2px
        border-radius: 100%
        z-index: 1
        background: var(--brandbook-more)
    svg
      transition: .5s ease
      &:hover
        color: var(--brandbook-main)
  &__burger
    display: none
    +screen('max', 767)
      display: block
  &__lk
    +screen('max',700)
      display: none
.nav
  +screen('max', 767)
    display: none
  &__list
    display: flex
  &__item
    margin-bottom: 0
    position: relative
    +screen('max', 1100)
      display: none
    &:hover
      .nav__dropdown
        display: block
    &:nth-of-type(-n + 4)
      +screen('max', 1100)
        display: block
  &__link
    +fs(15px)
    text-transform: uppercase
    color: var(--base-cristal-white)
    font-weight: 600
    padding: 1.75rem 3rem
    box-sizing: border-box
    line-height: 1
    display: inline-block
    +screen('max', 1500)
      padding: 1.75rem
    +screen('max', 1200)
      padding: 1.75rem .8rem
    &:hover
      background: rgba(2, 13, 30, .2)
  &__dropdown
    position: fixed
    top: 11rem
    left: 0
    width: 100vw
    background: var(--brandbook-main)
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    backdrop-filter: blur(56px)
    z-index: -2
    padding-block: 3.2rem
    box-sizing: border-box
    display: none
  &__drop-box
    max-width: 1100px
    margin: 0 auto
    display: flex
    justify-content: space-between
    align-items: center
    gap: 7rem
  &__level
    display: flex
    flex-flow: column
    gap: 1.6rem
  &__banner
    width: 334px
    height: 190px
    border-radius: 8px
    object-fit: cover
  &__level-link
    +fs(16px)
    font-weight: 500
    color: var(--base-cristal-white)
    display: inline-block
    border-bottom: 1px solid transparent
    line-height: 1.2
    &:hover
      border-bottom-color: var(--base-cristal-white)

.mobile-nav
  position: fixed
  top: 0
  left: 0
  backdrop-filter: blur(56px)
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25)
  background: linear-gradient(142deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.15) 100%)
  width: calc(100% - 3rem)
  z-index: 9999
  max-height: 100%
  overflow: auto
  transform: translateX(-100%)
  opacity: 0
  visibility: hidden
  transition: .5s ease
  &.open
    transform: none
    opacity: 1
    visibility: visible
  &__search
    width: 100%
    display: flex
    align-items: center
    border-bottom: 1px solid rgba(255, 255, 255, .2)
    padding: 2.2rem
    box-sizing: border-box
    gap: 1rem
    svg
      width: 15px
      height: 15px
      color: var(--base-cristal-white)
      flex-shrink: 0
  &__field
    +fs(16px)
    font-weight: 500
    background: none
    color: var(--base-cristal-white)
    &::placeholder
      color: var(--base-cristal-white)
  &__list
    margin: 1rem 0
  &__item
    margin: 0 2rem
  &__link
    margin-bottom: 0
    +fs(15px)
    font-weight: 600
    text-transform: uppercase
    color: var(--base-cristal-white)
    padding: 1.9rem 1.4rem
    line-height: 1
    display: inline-block
    transition: .5s ease
    &.open
      background: rgba(2, 13, 30, .5)
  &__dropdown
    backdrop-filter: blur(56px)
    background: var(--brandbook-main)
    padding: 3rem 2.6rem
    margin: 0 -2rem
    display: none
  &__level-item
    margin-bottom: 2rem
    &:last-of-type
      margin-bottom: 0
  &__level-link
    +fs(16px)
    font-weight: 600
    line-height: 1.5
    color: var(--base-cristal-white)
    &.active
      text-decoration: underline
      text-decoration-skip-ink: revert
  &__lk
    padding: 3rem 2.4rem
    box-sizing: border-box
    border-top: 1px solid rgba(255, 255, 255, .2)
    border-bottom: 1px solid rgba(255, 255, 255, .2)
    display: flex
    align-items: center
    gap: 1rem
    +fs(14px)
    font-weight: 500
    color: var(--base-cristal-white)
    svg
      width: 18px
      height: 18px
      color: var(--base-cristal-white)
  &__city
    display: flex
    align-items: center
    gap: 1rem
    flex-shrink: 0
    margin: 3rem 2.4rem
    svg
      width: 18px
      height: 18px
      color: var(--base-cristal-white)
      opacity: 0.3
  &__city-select
    background: none
    border: none
    color: var(--base-cristal-white)
    +fs(16px)
    font-weight: 500
    font-family: var(--font-primary)
  &__phone
    font-weight: 500
    display: flex
    align-items: center
    gap: 1rem
    color: var(--base-cristal-white)
    flex-shrink: 0
    transition: .5s ease
    margin: 3rem 2.4rem
    &:hover
      color: var(--brandbook-main)
    svg
      width: 18px
      height: 18px
      opacity: 0.3
  &__social
    display: flex
    padding-inline: 2.4rem
    gap: 1.4rem
    margin-bottom: 3rem
  &__soc-link
    border-radius: 30px
    backdrop-filter: blur(20px)
    background: linear-gradient(107.01deg, rgba(0, 0, 0, 0.41) 30.416%,rgba(0, 0, 0, 0.29) 101.259%)
    padding: 1.4rem
    box-sizing: border-box
    transition: .5s ease
    &:hover
      background: var(--buttons-white)
  &__icon
    width: 18px
    height: 18px
  &__close
    position: absolute
    top: 2.3rem
    right: 1.7rem