h1, .display-1
  color: inhert
h2, .display-2
  color: inhert
h3, .display-3
  color: inhert
h4, .display-4
  color: inhert

.text-uppercase
  text-transform: uppercase
.text-right
  text-align: right
.text-center
  text-align: center
.text-underline
  text-decoration: underline