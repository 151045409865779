.video-catalog
  position: relative
  &__pic
    +screen('max', 900)
      width: 100%
      height: 400px
      object-fit: cover
    +screen('max', 767)
      height: 292px
  &__btn
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 3