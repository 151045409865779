.catalog-type
  position: relative
  z-index: 3
  &__list
    display: flex
    flex-direction: column
    flex-wrap: wrap
    gap: 3.5rem
    height: 711px
    margin: 4rem 0
    +screen('max', 1500)
      transform: scale(.9) translateX(-5%)
      margin: 0
    +screen('max', 1360)
      transform: scale(.8) translateX(-13%)
    +screen('max', 1100)
      transform: none
      flex-direction: row
      height: auto
      margin: 4rem 0
  &__item
    position: relative
    max-height: 100%
    +screen('max', 767)
      width: 100%
    &:hover
      .catalog-type__title
        opacity: 1
  &__pic
    border-radius: 8px
    +screen('max', 767)
      width: 100%
  &__title
    position: absolute
    bottom: 0
    font-weight: 400
    font-size: max(68px, 90%)
    line-height: 0.7
    z-index: 3
    background: linear-gradient(90.00deg, rgb(255, 255, 255),rgba(255, 255, 255, 0.34))
    background-clip: text
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    display: inline-block
    text-transform: uppercase
    left: 0
    padding: 3.5rem
    box-sizing: border-box
    opacity: 0
    transition: .75s ease
    +screen('max', 767)
      +fs(28px)
      padding: 2rem
    b
      text-transform: lowercase
      display: block
      +fs(18.85px)

.banner-sales
  margin-top: 11rem
  position: relative
  z-index: 3
  +screen('max', 767)
    margin-top: 6.2rem
  &__slide
    position: relative
  &__inner
    position: absolute
    top: 0
    left: calc((100% - 1400px) / 2)
    padding-top: 3rem
    +screen('max', 1500)
      left: 6rem
    +screen('max', 767)
      left: 1.6rem
      width: calc(100% - 3.2rem)
  &__title
    +fs(70px)
    line-height: 1
    margin-bottom: 3.5rem
    +screen('max', 900)
      +fs(55px)
    +screen('max', 767)
      +fs(40px)
      margin-bottom: 3rem
  &__sign
    +fs(30px)
    font-weight: 500
    max-width: 750px
    +screen('max', 900)
      +fs(26px)
    +screen('max', 767)
      +fs(20px)
    b
      font-style: italic
      font-family: var(--font-secondary)
    span
      display: inline-block
      font-weight: 700
      color: var(--brandbook-main)
      padding: 0 0.5rem
      background: var(--base-rich-black)
  &__pic
    +screen('max', 900)
      height: 220px
      object-fit: cover
      object-position: center
    +screen('max', 767)
      height: 291px
      object-position: right
  .slick-slide
    height: auto
  .slick-arrow
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 3
  .slick-prev
    display: none!important
  .slick-next
    right: calc((100% - 1440px) / 2)
  .slick-dots
    position: absolute
    bottom: 3.5rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    gap: 2rem
    +screen('max', 900)
      display: none !important
    +screen('max', 767)
      display: flex !important
      bottom: 2rem
    button
      font-weight: 500
      color: var(--base-cristal-white)
      +fs(16px)
    li
      position: relative
      &.slick-active
        &:before
          content: ''
          width: 20px
          height: 20px
          position: absolute
          top: 50%
          left: 50%
          translate: -50% -40%
          background: var(--base-cristal-white)
          border-radius: 100%
          z-index: 2
          transition: .5s ease
  & + .heading
    margin-top: 3.5rem

.products-list
  padding-block: 6rem
  position: relative
  z-index: 9
  &__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 3.5rem
    +screen('max', 900)
      grid-template-columns: repeat(3, 1fr)
    +screen('max', 767)
      grid-template-columns: 1fr

.prod-item
  border-radius: 8px
  transition: .5s ease
  position: relative
  +screen('max', 767)
    box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.2)
    background: var(--base-cristal-white)
  &:hover
    box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.2)
    background: var(--base-cristal-white)
    .products-list__control
      opacity: 1
  &__top
    position: relative
  &__pic
    width: 100%
    border-radius: 8px
    vertical-align: middle
  &__control
    position: absolute
    top: 2rem
    right: 2rem
    display: flex
    flex-flow: column
    justify-content: center
    gap: 2rem
    opacity: 0
    transition: .5s ease
    +screen('max', 767)
      opacity: 1
  &__btn
    padding: 1.4rem
    border-radius: 50px
    background: rgb(130, 211, 26)
    color: var(--base-cristal-white)
    svg
      width: 18px
      height: 18px
  &__favorite
    color: #E2E9F1
    svg
      width: 24px
      height: 24px
  &__bottom
    padding: .8rem 1.6rem 1rem
    box-sizing: border-box
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: .5rem
  &__state
    +fs(12px)
    text-transform: lowercase
    color: #82D31A
  &__foot
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
  &__title
    +fs(18px)
    font-weight: 500
    line-height: 1.4
  &__prices
    display: flex
    flex-flow: column
    gap: .5rem
    flex-shrink: 0
  &__old
    +fs(14px)
    color: #989090
    &::after
      content: attr(data-sale)
      color: #FF0000
  &__new
    font-weight: 500
    +fs(20px)
  &__numbers
    display: flex
    align-items: center
    justify-content: center
    gap: 1rem
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    visibility: hidden
    transition: .5s ease
    background: rgba(255, 255, 255, .8)
    +screen('max', 767)
      height: auto
      bottom: 0
      top: auto
      padding-block: 1rem
  &__bt
    background: var(--brandbook-main)
    width: 62px
    height: 64px
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    font-weight: 700
    +fs(20px)
    text-align: center
    color: var(--base-cristal-white)
    line-height: 1
    z-index: 1
    position: relative
    transition: .5s ease
    cursor: pointer
    +screen('max', 767)
      height: 52px
      width: 52px
    &:before
      content: ''
      width: 100%
      height: 100%
      border-radius: 50px
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(360deg, #73b61c 0%, #558813 100%)
      z-index: -1
      opacity: 0
      visibility: hidden
      transition: .5s ease
    &:hover
      &:before
        opacity: 1
        visibility: visible
  &__numb
    text-align: center
    width: 45px
    +fs(32px)
    padding: 0
    background: none
    -moz-appearance: textfield
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
  &__remove
    display: inline-flex
    align-items: center
    gap: 1rem
    +fs(18px)
    font-weight: 500
    color: var(--brandbook-main)
    opacity: 0
    transition: .5s ease
    position: absolute
    left: 0
    top: calc(100% + 10px)

.product
  position: relative
  .heading
    margin-top: 3.5rem
    margin-bottom: 6rem
    &__title
      +fs(62px)
      +screen('max', 1200)
        +fs(47px)
      +screen('max', 767)
        +fs(24px)
        margin-bottom: 3.4rem
  &__slider-nav
    margin-bottom: -349px
    +screen('max', 900)
      display: none
    .slick-list
      padding-left: calc((100% - 1440px) / 2)
    .slick-slide
      width: 293px
      height: auto
      +screen('max', 1200)
        width: 200px
    .slick-current
      width: 720px
      height: 293px
      overflow: hidden
      +screen('max', 1200)
        width: 50vw
      .product__pic
        opacity: 0
  &__slider-main
    position: relative
    .slick-slide
      height: auto
    .slick-arrow
      position: absolute
      bottom: 13rem
      z-index: 3
      border-color: rgba(188, 190, 195, 0.3)
      &:hover
        border-color: var(--brandbook-main)
        img
          filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%)
    .slick-prev
      left: 1.8rem
    .slick-next
      right: 1.8rem
    .slick-dots
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      gap: 2rem
      margin-top: 2rem
      button
        font-weight: 500
        color: #b7b7b7
        +fs(16px)
      li
        position: relative
        &.slick-active
          &:before
            content: ''
            width: 20px
            height: 20px
            position: absolute
            top: 50%
            left: 50%
            translate: -50% -40%
            background: #b7b7b7
            border-radius: 100%
            z-index: 2
            transition: .5s ease
  &__pic
    width: 100%
    max-height: 100%
  &__inner
    display: flex
    justify-content: space-between
    align-items: flex-end
    margin-top: 5.6rem
    gap: 2rem
    +screen('max', 900)
      display: block
    +screen('max', 767)
      margin-top: 3.4rem
  &__slider-main
    width: 50%
    max-width: 720px
    +screen('max', 900)
      width: 100%
  &__info
    max-width: 601px
    width: 50%
    position: relative
    z-index: 3
    +screen('max', 900)
      width: 100%
      max-width: 100%
  &__top, &__middle, &__foot
    display: flex
    justify-content: space-between
    margin-top: 7rem
    +screen('max', 1500)
      margin-top: 3rem
    +screen('max', 1100)
      margin-top: 1rem
      align-items: center
    +screen('max', 900)
      margin-top: 5rem
    +screen('max', 767)
      margin-top: 2rem
    +screen('max', 767)
      flex-flow: column
      gap: 3rem
  &__foot
    +screen('max', 767)
      align-items: flex-end
  &__left
    +screen('max', 767)
      width: 100%
      display: flex
      justify-content: space-between
      flex-wrap: wrap
  &__right
    +screen('max', 767)
      width: 100%
      text-align: right
  &__art
    +fs(18px)
    line-height: 156%
    color: #949393
    margin-bottom: 2rem
    font-weight: 500
    +screen('max', 767)
      margin-bottom: 0
  &__holder
    display: flex
    align-items: center
    gap: 5rem
    margin-top: 2rem
    +screen('max', 767)
      flex-grow: 1
      justify-content: space-between
      margin-top: 0
  &__state
    font-weight: 500
    +fs(18px)
    line-height: 156%
    &_green
      color: var(--brandbook-main)
  &__favorite
    color: var(--brandbook-more)
    opacity: 0.8
    svg
      width: 24px
      height: 24px
  &__old
    +fs(21px)
    line-height: 130%
    color: #949393
    text-align: right
    &:after
      content: attr(data-sale)
      font-weight: 600
      color: var(--brandbook-more)
      margin-left: 2.5rem
  &__new
    font-weight: 500
    +fs(60px)
    line-height: 100%
    text-align: center
    color: var(--base-rich-black)
    +screen('max', 1100)
      +fs(40px)
    +screen('max', 900)
      +fs(60px)
    +screen('max', 767)
      +fs(50px)
      text-align: right
  &__numbers
    display: flex
    align-items: center
    gap: 1rem
  &__bt
    background: var(--brandbook-main)
    width: 62px
    height: 64px
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    font-weight: 700
    +fs(20px)
    text-align: center
    color: var(--base-cristal-white)
    line-height: 1
    z-index: 1
    position: relative
    transition: .5s ease
    cursor: pointer
    &:before
      content: ''
      width: 100%
      height: 100%
      border-radius: 50px
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(360deg, #73b61c 0%, #558813 100%)
      z-index: -1
      opacity: 0
      visibility: hidden
      transition: .5s ease
    &:hover
      &:before
        opacity: 1
        visibility: visible
  &__numb
    text-align: center
    width: 45px
    +fs(32px)
    padding: 0
    background: none
    -moz-appearance: textfield
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
  &__btn
    border-radius: 50px
    padding: 28px 0px
    display: flex
    justify-content: center
    align-items: center
    width: min(334px, 55%)
    gap: 2rem
    color: var(--base-cristal-white)
    font-weight: 600
    +fs(18px)
    background: var(--brandbook-main)
    position: relative
    z-index: 1
    transition: .5s ease
    +screen('max', 767)
      width: 100%
    svg
      width: 18px
      height: 18px
    &:before
      content: ''
      width: 100%
      height: 100%
      border-radius: 50px
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(360deg, #73b61c 0%, #558813 100%)
      z-index: -1
      opacity: 0
      visibility: hidden
      transition: .5s ease
    &:hover
      &:before
        opacity: 1
        visibility: visible
  &__colors
    display: flex
    justify-content: flex-end
    gap: 1.5rem
    +screen('max', 1100)
      gap: 0.5rem
  &__hidden
    display: none
    &:checked + span
      &::before
        opacity: 1
        visibility: visible
  &__color-type
    width: 52px
    height: 52px
    border-radius: 100%
    display: block
    position: relative
    cursor: pointer
    +screen('max', 1100)
      width: 40px
      height: 40px
    +screen('max', 767)
      width: 52px
      height: 52px
    &.blue
      background: linear-gradient(180deg, #98d4ff 0%, #000 100%)
    &.green
      background: linear-gradient(180deg, #82d31a 0%, #000 100%)
    &.pink
      background: linear-gradient(180deg, #ff98dc 0%, #000 100%)
    &.orange
      background: linear-gradient(180deg, #ffe298 0%, #000 100%)
    &.red
      background: linear-gradient(180deg, #ff9898 0%, #000 100%)
    &:before
      content: ''
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      border-radius: 100%
      border: 3px solid var(--brandbook-more)
      z-index: 2
      box-sizing: border-box
      transition: .5s ease
      opacity: 0
      visibility: hidden

.product-info
  position: relative
  z-index: 3
  margin-top: 3rem
  padding-bottom: 9rem
  &__shops
    display: flex
    justify-content: space-between
    align-items: center
    gap: 1rem
    +screen('max', 900)
      flex-wrap: wrap
  &__link
    +screen('max', 767)
      width: 100%
    svg
      width: 100%
      +screen('max', 767)
        height: 96px
    &:hover
      .oz-bg
        fill: #2358f3
      .oz-txt, .wb-text, .ya-over, .mm-txt
        fill: var(--base-cristal-white)
      .wb-bg
        fill: url(#wb-hover)
      .ya-bg
        fill: #fa3f1d
      .ya-letter
        fill: #fa3f1d
      .mm-bg
        fill: #9553d1
  &__text-box
    padding: 5rem 0 3.5rem
    font-weight: 500
    +fs(18px)
    line-height: 156%
    +screen('max', 767)
      padding: 3rem 0
    p
      margin-bottom: 2rem
      &:last-of-type
        margin-bottom: 0
  &__open-tab
    display: inline-flex
    align-items: center
    padding: 1.5rem 5rem 1.5rem 2rem
    gap: 2rem
    position: relative
    +fs(18px)
    font-weight: 500
    border-bottom: 1px solid var(--brandbook-main)
    flex-shrink: 0
    margin-top: 5rem
    cursor: pointer
    box-sizing: border-box
    +screen('max', 1023)
      +fs(16px)
    +screen('max', 767)
      width: 100%
    &:after
      content: ''
      width: 16px
      height: 10px
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      background-repeat: no-repeat
      background-size: contain
      position: absolute
      right: 2rem
      top: 50%
      transform: translateY(-50%) rotate(180deg)
    &.open
      &:after
        transform: translateY(-50%)
  &__btn
    margin-top: 3.5rem
    +screen('max', 767)
      margin: 3rem auto 0
      display: flex
      max-width: 282px
.product-slider
  position: relative
  z-index: 3
  margin: 9rem 0
  +screen('max', 900)
    margin: 5rem 0
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    padding-right: 3.5rem
    margin-bottom: 4rem
  &__title
    +fs(72px)
    line-height: 1
    font-weight: 700
    +screen('max', 900)
      +fs(57px)
    +screen('max', 767)
      +fs(24px)
  &__control
    flex-shrink: 0
  .slick-arrow
    border-color: rgba(185, 187, 193, 0.3)
    margin-left: 2rem
    &:hover
      border-color: var(--brandbook-main)
      img
        filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%)
  .slick-slide
    margin-right: 3.5rem
    height: auto
    +screen('max', 767)
      margin-right: 0
  .slick-dots
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    gap: 1rem
    margin-top: 2rem
    button
      font-weight: 500
      color: #b7b7b7
      +fs(12px)
    li
      position: relative
      &.slick-active
        &:before
          content: ''
          width: 14px
          height: 14px
          position: absolute
          top: 50%
          left: 50%
          translate: -50% -40%
          background: #b7b7b7
          border-radius: 100%
          z-index: 2
          transition: .5s ease

.product-buy
  position: relative
  z-index: 3
  padding-block: 5rem 7rem
  background: #e5e5e5
  +screen('max', 767)
    padding-block: 3rem
  &__title
    +fs(72px)
    line-height: 1
    margin-bottom: 4rem
    font-weight: 700
    +screen('max', 900)
      +fs(57px)
    +screen('max', 767)
      +fs(24px)
  &__list
    display: grid
    grid-template-columns: repeat(4,1fr)
    gap: 3.5rem
    +screen('max', 900)
      grid-template-columns: repeat(2, 1fr)
    +screen('max', 767)
      grid-template-columns: 1fr
    .prod-item
      &:nth-of-type(3), &:nth-of-type(4)
        +screen('max', 767)
          display: none
  &__control
    text-align: center
    margin-top: 3rem
    display: none
    +screen('max', 767)
      display: block
