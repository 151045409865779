.poster-type-one
  position: relative
  &__pic
    position: relative
    z-index: -1
    width: 100%
    object-fit: cover
    height: 395px
    +screen('max', 767)
      height: 480px
  &__outer-pic
    position: absolute
    width: 100%
    height: 100%
    z-index: 2
    bottom: 0
    right: 0
    object-fit: contain
    object-position: bottom
    +screen('max', 1023)
      width: 130%
      object-position: bottom right
    +screen('max', 767)
      width: 200%
      object-position: 100px bottom
  &__content
    position: absolute
    top: 6rem
    left: max(4rem, 15.125vw)
    z-index: 4
    +screen('max', 1023)
      left: 3rem
    +screen('max', 767)
      left: 2rem
      top: 3.5rem
      width: calc(100% - 4rem)
  &__sign
    display: flex
    align-items: center
    gap: 2rem
    margin-bottom: 2.6rem
    span
      font-weight: 500
      +fs(18px)
    &_white
      color: var(--base-cristal-white)
  &__icon
    width: 24px
    height: 24px
  &__title
    +fs(40px)
    color: var(--base-cristal-white)
    line-height: 1.2
    +screen('max', 767)
      +fs(30px)
    i
      font-family: var(--font-secondary)
    span
      font-weight: 700
      display: block
      +screen('max', 767)
        +fs(18px)
  &__btn
    margin-top: max(2rem, 4.5vw)

.poster-type-two
  position: relative
  padding: 7rem 0 3.5rem
  background-image: url(../img/poster-5.png)
  background-repeat: no-repeat
  background-position: center top
  background-repeat: no-repeat
  background-size: contain
  +screen('max', 1023)
    padding: 5rem 0 3.5rem
    background-size: 200%
    background-position: 70% top
  +screen('max', 767)
    background-size: 455%
    background-position: 71% top
  &:before
    content: ''
    width: 100vw
    height: 65vw
    position: absolute
    top: 0%
    left: 50%
    transform: translate(-50%, -20%)
    background-image: url(../icon/wave-2.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: 100%
  &__inner
    position: relative
    z-index: 2
  &__title
    margin: 3rem 0
    +fs(70px)
    line-height: 1
    max-width: 650px
    width: 100%
    text-wrap: balance
    +screen('max', 1100)
      +fs(50px)
    +screen('max', 767)
      +fs(40px)
  &__icon
    vertical-align: middle
    +screen('max', 767)
      width: 100%
  &__bottom
    display: flex
    justify-content: space-between
    align-items: flex-end
    +screen('max', 1023)
      flex-wrap: wrap
  &__pic
    margin-bottom: 7rem
    +screen('max', 1400)
      width: 50%
    +screen('max', 1023)
      width: 70%
      margin-bottom: 0
    +screen('max', 767)
      width: 100%
  &__subscribe
    max-width: 605px
    width: 50%
    +screen('max', 1023)
      width: 100%
      margin-right: 0
      margin-left: auto
  &__action
    border-radius: 50px
    background: rgba(0, 0, 0, 0.4)
    padding: 1.1rem
    box-sizing: border-box
    display: flex
    align-items: center
    gap: 2rem
    position: relative
    +screen('max', 767)
      padding: 2rem 1.1rem
      margin-bottom: 70px
  &__field
    +fs(18px)
    font-weight: 500
    line-height: 1
    padding-left: 7rem
    color: var(--base-cristal-white)
    background: none
    flex-grow: 1
    &::placeholder
      color: var(--base-cristal-white)
  &__ic
    position: absolute
    left: 4rem
    top: 50%
    transform: translateY(-50%)
    width: 18px
    height: 18px
    color: var(--base-cristal-white)
  &__submit
    border-radius: 50px
    background: rgb(130, 211, 26)
    padding: 1.6rem 2.4rem
    box-sizing: border-box
    font-weight: 500
    +fs(18px)
    color: var(--base-cristal-white)
    line-height: 1
    position: relative
    z-index: 1
    +screen('max', 767)
      position: absolute
      bottom: -60px
      right: 0
    &:before
      content: ''
      width: 100%
      height: 100%
      border-radius: 50px
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(360deg, #73b61c 0%, #558813 100%)
      z-index: -1
      opacity: 0
      visibility: hidden
      transition: .5s ease
    &:hover
      &:before
        opacity: 1
        visibility: visible
  &__txt
    margin-top: 3.5rem
    +fs(30px)
    text-align: right
    font-weight: 500
    b
      font-style: italic
      font-family: var(--font-secondary)

.poster-type-three
  position: relative
  z-index: 2
  &__inner
    position: relative
    +screen('max', 1023)
      padding: 0 1.6rem
  &__pic
    width: 100%
    object-fit: cover
    height: 395px
    border-radius: 8px
    +screen('max', 1023)
      height: 420px
    +screen('max', 767)
      height: 395px
  &__content
    position: absolute
    top: 6rem
    left: max(4rem, 6vw)
    z-index: 4
    +screen('max', 767)
      top: 4rem
      left: 4rem
      width: calc(100% - 8rem)
  &__sign
    display: flex
    align-items: center
    gap: 2rem
    margin-bottom: 1.8rem
    span
      font-weight: 500
      +fs(18px)
  &__icon
    width: 24px
    height: 24px
  &__title
    +fs(40px)
    line-height: 1.2
    +screen('max', 767)
      +fs(30px)
    i
      font-family: var(--font-secondary)
    span
      font-weight: 700
      display: block
      +screen('max', 767)
        +fs(19px)
  &__btn
    margin-top: max(2rem, 4.5vw)
    +screen('max', 767)
      border-color: var(--base-cristal-white)
      color: var(--base-cristal-white)
    &_right
      position: absolute
      right: 9rem
      bottom: 5rem
      margin-top: 0
      +screen('max', 1023)
        right: auto
        left: 3.2rem

.poster-type-four
  padding: 5rem 0
  background-image: url(../img/poster-3.png)
  background-repeat: no-repeat
  background-position: center
  position: relative
  margin-bottom: 3rem
  &:before
    content: ''
    width: 100vw
    height: 65vw
    position: absolute
    top: 0%
    left: 50%
    transform: translate(-50%, -20%)
    background-image: url(../icon/wave-3.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: 100%
  &__inner
    position: relative
    z-index: 2
  &__title
    +fs(70px)
    line-height: 1
    margin-bottom: 3rem
    +screen('max', 1023)
      +fs(55px)
    +screen('max', 767)
      +fs(40px)
  &__sign
    +fs(30px)
    font-weight: 500
    margin-bottom: 4.5rem
    +screen('max', 767)
      +fs(24px)

.poster-type-five
  padding: 5rem 0
  background-image: url(../img/poster-3.png)
  background-repeat: no-repeat
  background-position: top
  position: relative
  margin-bottom: 3rem
  &:before
    content: ''
    width: 100vw
    height: 65vw
    position: absolute
    top: 0%
    left: 50%
    transform: translate(-50%, -20%)
    background-image: url(../icon/wave-2.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: 100%
  &__inner
    position: relative
    z-index: 2
  &__title
    +fs(70px)
    line-height: 1
    margin-bottom: 3rem
    +screen('max', 900)
      +fs(55px)
    +screen('max', 767)
      +fs(40px)
  &__sign
    +fs(30px)
    font-weight: 500
    margin-bottom: 4.5rem
    +screen('max', 900)
      +fs(26px)
    +screen('max', 767)
      +fs(24px)
  &__subscribe
    max-width: 605px
    width: 50%
    margin-right: 0
    margin-left: auto
    margin-top: 5rem
    +screen('max', 900)
      width: 70%
    +screen('max', 767)
      width: 100%
      margin-top: 8rem
  &__action
    border-radius: 50px
    background: rgba(0, 0, 0, 0.4)
    padding: 1.1rem
    box-sizing: border-box
    display: flex
    align-items: center
    gap: 2rem
    position: relative
    +screen('max', 767)
      padding: 2rem 1.1rem
      margin-bottom: 70px
  &__field
    +fs(18px)
    font-weight: 500
    line-height: 1
    padding-left: 7rem
    color: var(--base-cristal-white)
    background: none
    flex-grow: 1
    &::placeholder
      color: var(--base-cristal-white)
  &__ic
    position: absolute
    left: 4rem
    top: 50%
    transform: translateY(-50%)
    width: 18px
    height: 18px
    color: var(--base-cristal-white)
  &__submit
    border-radius: 50px
    background: rgb(130, 211, 26)
    padding: 1.6rem 2.4rem
    box-sizing: border-box
    font-weight: 500
    +fs(18px)
    color: var(--base-cristal-white)
    line-height: 1
    +screen('max', 767)
      position: absolute
      bottom: -60px
      right: 0
  &__txt
    margin-top: 3.5rem
    +fs(30px)
    text-align: right
    font-weight: 500
    b
      font-style: italic
      font-family: var(--font-secondary)

.poster-prod
  position: relative
  margin-bottom: 3.5rem
  &__pic
    width: 100%
    vertical-align: middle
    height: 425px
    object-fit: cover
    +screen('max', 767)
      object-position: -10vw
      height: auto
      object-position: 0
  &__content
    position: absolute
    top: 4rem
    left: clamp(2rem, 10vw, 30rem)
    +screen('max', 900)
      left: 4rem
    +screen('max', 767)
      width: calc(100% - 3.2rem)
      left: 1.6rem
    &_mod
      width: 70vw
      top: 10rem
      +screen('max', 1200)
        width: 80vw
      +screen('max', 900)
        width: 90vw
  &__title
    +fs(70px)
    line-height: 1
    margin-bottom: 2rem
    +screen('max', 900)
      +fs(55px)
    +screen('max', 767)
      font-size: min(40px, 12vw)
    &_right
      position: absolute
      right: 0
      text-align: right
      +screen('max', 900)
        width: 40%
        +fs(50px)
      +screen('max', 767)
        right: 1.6rem
        width: 70%
        font-size: min(40px, 12vw)
    b
      font-style: italic
      font-family: var(--font-secondary)
  &__sign
    +fs(30px)
    font-weight: 500
    line-height: 1.2
    margin-bottom: 2rem
    +screen('max', 900)
      +fs(30px)
    +screen('max', 767)
      font-size: min(30px, 6vw)
    b
      font-style: italic
      font-family: var(--font-secondary)
  &__list
    +screen('max', 767)
      display: none
    li
      padding-left: 3.6rem
      margin-bottom: 1rem
      font-weight: 500
      +fs(18px)
      line-height: 1.5
      background-image: url(../icon/icon-check.svg)
      background-repeat: no-repeat
      background-position: left
      background-size: 16px
