.catalog-mini
  position: relative
  padding: 5rem 0
  &:before
    content: ''
    width: 110vw
    height: 65vw
    position: absolute
    top: 0%
    left: 50%
    transform: translate(-50%, -50%)
    background-image: url(../icon/wave-1.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: 100%
    +screen('max', 1023)
      width: 150vw
      height: 100vh
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 2rem
    position: relative
    z-index: 3
    +screen('max', 1100)
      grid-template-columns: repeat(2, 1fr)
    +screen('max', 1023)
      gap: 3rem
    +screen('max', 767)
      grid-template-columns: 1fr
  &__link
    display: flex
    align-items: flex-start
    gap: 2rem
    padding: 1.5rem
    box-sizing: border-box
    border-radius: 8px
    transition: .5s ease
    &:hover
      background: var(--brandbook-main)
      color: var(--base-cristal-white)
      .catalog-mini__icon
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(7%) hue-rotate(168deg) brightness(102%) contrast(106%)
  &__icon
    width: 55px
    height: 55px
    flex-shrink: 0
    transition: .5s ease
  &__title
    font-weight: 700
    +fs(40px)
    line-height: 0.8
    margin-bottom: 1rem
    display: block
    +screen('max', 1023)
      +fs(30px)
  &__sign
    +fs(18px)
    font-weight: 500
    line-height: 1.45
    display: block

.catalog
  position: relative
  padding: 5rem 0
  &__container
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 5rem
    position: relative
    z-index: 3
    +screen('max', 1200)
      gap: 2rem
    +screen('max', 1080)
      grid-template-columns: repeat(2, 1fr)
    +screen('max', 767)
      grid-template-columns: 1fr
  &__box
    display: flex
    align-items: flex-start
    gap: 2rem
    flex-shrink: 0
  &__icon
    width: 55px
    height: 55px
    position: relative
    +screen('max', 767)
      width: 40px
      height: 40px
      top: -10px
  &__title
    font-weight: 700
    +fs(40px)
    line-height: 0.8
    margin-bottom: 1rem
    display: block
    height: 72px
    +screen('max', 1200)
      +fs(30px)
    +screen('max', 767)
      +fs(24px)
      height: auto
  &__sign
    +fs(18px)
    font-weight: 500
    line-height: 1.45
    display: block
  &__list
    margin-top: 2.5rem
  &__item
    margin-bottom: 2.5rem
  &__link
    font-weight: 500
    +fs(18px)
    &:hover
      color: var(--brandbook-main)