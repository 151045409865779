html
  font-size: 10px
  overflow-x: hidden
body
  font-size: 16px
  font-weight: 400
  font-family: var(--font-primary)
  background: rgb(244, 247, 255)
  overflow-x: hidden

.inner
  max-width: 1520px
  width: 100%
  padding: 0 4rem
  margin: 0 auto
  box-sizing: border-box
  +screen('max', 1023)
    padding: 0 3.2rem
  +screen('max', 600)
    padding: 0 1.6rem

.content
  padding-block: 5rem
  font-weight: 500
  +fs(18px)
  line-height: 156%
  p
    margin-bottom: 2rem
    &:last-of-type
      margin-bottom: 0
