:root
  --font-primary: 'Golos Text', Arial, Helvetica, sans-serif
  --font-secondary: 'Enter Sansman', Arial, Helvetica, sans-serif
  --base-cristal-white: #fff
  --base-rich-black: #020d1e
  --brandbook-main: #73b61c
  --brandbook-sky: #aacbfc
  --brandbook-barbie: #ff00c7
  --brandbook-grass: #9747ff
  --brandbook-more: #f22a52
  --buttons-white: #fff
  --buttons-yellow: #fee000
  --buttons-green: #2dbe64
  --buttons-blue: #69a6ff
  --buttons-red: #fe4928
  --buttons-violet: #7b61ff
  --gray-gray-10: #fafaff
  --gray-gray-20: #f5f5ff
  --gray-gray-30: #e6e6f0
  --gray-gray-40: #bcbccc
  --gray-gray-50: #9393a3
  --gray-gray-60: #67677a
  --gray-gray-70: #3e3e52
  --tech-hot-red: #f00
  --tech-sky: #00a3ff
  --tech-greentekila: #0ff14e
  --tech-barbie: #ff00a8
  --green: #558813
