.heading
  margin-top: 14.7rem
  position: relative
  z-index: 3
  +screen('max', 767)
    margin-top: 7rem
  &__title
    margin-top: 3.5rem
    +fs(72px)
    line-height: 1
    +screen('max', 1200)
      +fs(60px)
    +screen('max', 767)
      +fs(50px)
      margin-top: 2rem

.breadcrumb
  opacity: 0.3
  &__link
    +fs(12px)
    color: var(--base-rich-black)
    &:after
      content: '/'
      margin: 0 1rem
    &:last-child
      &:after
        content: none