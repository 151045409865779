.filter
  position: relative
  margin-top: 5rem
  +screen('max', 767)
    margin-top: 3rem
  &.open
    padding-bottom: 3.5rem
    border-bottom: 1px solid var(--brandbook-main)
    transition: .5s ease
    &:before
      content: ''
      width: 100%
      height: 247px
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(102, 102, 102, 0) 100%)
      opacity: 0.2
      position: absolute
      bottom: 0
      left: 0
  &__line
    display: flex
    gap: 1.5rem
    overflow: auto
    flex-grow: 1
  &__control
    display: flex
    gap: 3rem
    align-items: flex-start
    margin-top: 2.5rem
    +screen('max', 767)
      flex-flow: column-reverse
      gap: 1rem
      margin-top: 2rem
  &__btn
    flex-shrink: 0
  &__open
    display: flex
    align-items: center
    padding: 1.5rem 2rem
    gap: 2rem
    width: 210px
    position: relative
    +fs(18px)
    font-weight: 500
    border-bottom: 1px solid var(--brandbook-main)
    flex-shrink: 0
    +screen('max', 1023)
      +fs(16px)
    +screen('max', 767)
      width: 100%
    &:after
      content: ''
      width: 16px
      height: 10px
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      background-repeat: no-repeat
      background-size: contain
      position: absolute
      right: 2rem
      top: 50%
      transform: translateY(-50%)
    &.open
      &:after
        transform: translateY(-50%) rotate(180deg)
  &__list
    margin-top: 2rem
    display: grid
    grid-template-columns: repeat(5, minmax(auto, 210px))
    justify-content: space-between
    +screen('max', 1200)
      gap: 1rem
    +screen('max', 1023)
      grid-template-columns: repeat(3, minmax(auto, 210px))
    +screen('max', 767)
      grid-template-columns: 1fr
  &__all
    display: none
    position: relative
  &__check
    display: block
    margin-bottom: 1.2rem
  &__head
    padding: 1.5rem 2rem
    gap: 2rem
    position: relative
    +fs(18px)
    font-weight: 500
    cursor: pointer
    &:after
      content: ''
      width: 16px
      height: 10px
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      background-repeat: no-repeat
      background-size: contain
      position: absolute
      right: 2rem
      top: 50%
      transform: translateY(-50%)
      transition: .5s ease
  &__hidden
    display: none
    &:checked +
      .filter__txt
        &:after
          opacity: 1
          visibility: visible
  &__txt
    +fs(18px)
    font-weight: 500
    text-wrap: balance
    cursor: pointer
    position: relative
    padding-left: 4rem
    +screen('max', 1200)
      padding-left: 3rem
      +fs(16px)
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      border: 1px solid #bdbdbd
      width: 18px
      height: 18px
    &:after
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      width: 20px
      height: 20px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-size: contain
      opacity: 0
      visibility: hidden
      transition: .5s ease
  &__submit
    margin: 0 auto
    width: 157px
    display: flex
    align-items: center
    gap: 2rem
    margin-top: 3rem
    +screen('max', 1023)
      position: absolute
      right: 0
      bottom: 0
    +screen('max', 767)
      position: relative
    svg
      flex-shrink: 0
  &__slider
    display: flex
    padding: 1rem 2rem
    width: 100%
    border-radius: 100px
    background: var(--fon)
    box-sizing: border-box
    position: relative
    height: 75px
  &__group
    position: absolute
    top: 70%
    transform: translateY(-50%)
    display: flex
    align-items: center
    gap: .5rem
    border-radius: 8px
    background: var(--base-cristal-white)
    padding: 0.5rem 1rem
    font-weight: 600
    &_start
      left: 1rem
    &_end
      right: 1rem
.line
  position: relative
  width: 100%
  height: 12px
  background-color: #babdc2
  bottom: 0
.ui-slider-horizontal .ui-slider-range
  top: 0
  height: 100%
  position: absolute
  background: var(--brandbook-main)

.ui-slider-horizontal .ui-slider-handle
  top: -3px
  background-color: var(--base-rich-black)
  height: 17px
  width: 17px
  border-radius: 50%
  display: block
  position: absolute
  &:first-of-type
    transform: translateX(-5px)
  &:last-of-type
    transform: translateX(-7px)
