/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

:root {
  --font-primary: "Golos Text", Arial, Helvetica, sans-serif;
  --font-secondary: "Enter Sansman", Arial, Helvetica, sans-serif;
  --base-cristal-white: #fff;
  --base-rich-black: #020d1e;
  --brandbook-main: #73b61c;
  --brandbook-sky: #aacbfc;
  --brandbook-barbie: #ff00c7;
  --brandbook-grass: #9747ff;
  --brandbook-more: #f22a52;
  --buttons-white: #fff;
  --buttons-yellow: #fee000;
  --buttons-green: #2dbe64;
  --buttons-blue: #69a6ff;
  --buttons-red: #fe4928;
  --buttons-violet: #7b61ff;
  --gray-gray-10: #fafaff;
  --gray-gray-20: #f5f5ff;
  --gray-gray-30: #e6e6f0;
  --gray-gray-40: #bcbccc;
  --gray-gray-50: #9393a3;
  --gray-gray-60: #67677a;
  --gray-gray-70: #3e3e52;
  --tech-hot-red: #f00;
  --tech-sky: #00a3ff;
  --tech-greentekila: #0ff14e;
  --tech-barbie: #ff00a8;
  --green: #558813;
}

.btn {
  display: inline-block;
  border: 1px solid var(--base-rich-black);
  border-radius: 50px;
  padding: 1.3rem 2.4rem;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1em;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.btn_play {
  display: inline-flex;
  align-items: center;
  gap: 2rem;
  transition: 0.5s ease;
}
.btn_play:hover {
  border-color: var(--brandbook-main);
  color: var(--brandbook-main);
}
.btn_play:hover img {
  filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%);
}
.btn_reg {
  background: var(--brandbook-main);
  color: var(--base-cristal-white);
  width: 334px;
  gap: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125em;
  border-color: transparent;
  font-weight: 600;
  padding: 2.2rem 1rem;
  transition: 0.5s ease;
}
.btn_reg:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #73b61c 0%, #558813 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.btn_reg:hover:before {
  opacity: 1;
  visibility: visible;
}
.btn_white {
  color: var(--base-cristal-white);
  border-color: var(--base-cristal-white);
}
.btn_red {
  background: rgba(242, 42, 82, 0.2);
  border-color: transparent;
  color: var(--brandbook-more);
}
.btn_black {
  background: var(--base-rich-black);
  color: var(--base-cristal-white);
}
.btn_transparent-green {
  border-color: var(--brandbook-main);
  color: var(--brandbook-main);
  padding: 0.7rem 2rem;
  font-size: 0.875em;
}
.btn__icon {
  width: 16px;
  height: 16px;
}

.slick-arrow {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  padding: 2.4rem;
  box-sizing: border-box;
  transition: 0.5s ease;
}
.slick-arrow:hover {
  border-color: var(--base-cristal-white);
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-primary);
  background: rgb(244, 247, 255);
  overflow-x: hidden;
}

.inner {
  max-width: 1520px;
  width: 100%;
  padding: 0 4rem;
  margin: 0 auto;
  box-sizing: border-box;
}
@media screen and (max-width: 1023px) {
  .inner {
    padding: 0 3.2rem;
  }
}
@media screen and (max-width: 600px) {
  .inner {
    padding: 0 1.6rem;
  }
}

.content {
  padding-block: 5rem;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 156%;
}
.content p {
  margin-bottom: 2rem;
}
.content p:last-of-type {
  margin-bottom: 0;
}

.group {
  display: inline-block;
}

@font-face {
  font-family: "Golos Text";
  src: url("../font/GolosText-Regular.woff2") format("woff2"), url("../font/GolosText-Regular.woff") format("woff"), url("../font/GolosText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Golos Text";
  src: url("../font/GolosText-DemiBold.woff2") format("woff2"), url("../font/GolosText-DemiBold.woff") format("woff"), url("../font/GolosText-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Golos Text";
  src: url("../font/GolosText-Bold.woff2") format("woff2"), url("../font/GolosText-Bold.woff") format("woff"), url("../font/GolosText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Golos Text VF";
  src: url("../font/GolosTextVF-Regular.woff2") format("woff2"), url("../font/GolosTextVF-Regular.woff") format("woff"), url("../font/GolosTextVF-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Golos Text";
  src: url("../font/GolosText-Medium.woff2") format("woff2"), url("../font/GolosText-Medium.woff") format("woff"), url("../font/GolosText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Golos Text";
  src: url("../font/GolosText-Black.woff2") format("woff2"), url("../font/GolosText-Black.woff") format("woff"), url("../font/GolosText-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Enter Sansman";
  src: url("../font/EnterSansmanBold.woff2") format("woff2"), url("../font/EnterSansmanBold.woff") format("woff"), url("../font/EnterSansmanBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Enter Sansman";
  src: url("../font/EnterSansmanBoldItalic.woff2") format("woff2"), url("../font/EnterSansmanBoldItalic.woff") format("woff"), url("../font/EnterSansmanBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  height: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type=submit],
button {
  cursor: pointer;
  font-family: var(--font-primary);
  background: none;
  border: 0;
}
input[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
  font-family: var(--font-primary);
}

a {
  text-decoration: none;
  color: inherit;
  transition: 0.5s ease;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: var(--font-primary);
}

input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

li {
  margin-bottom: 10px;
}

summary::marker {
  content: none;
}
summary::-webkit-details-marker {
  display: none;
}

.clearfix::before {
  content: " ";
  display: table;
}
.clearfix::after {
  content: " ";
  display: table;
  clear: both;
}

h1, .display-1 {
  color: inhert;
}

h2, .display-2 {
  color: inhert;
}

h3, .display-3 {
  color: inhert;
}

h4, .display-4 {
  color: inhert;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.filter {
  position: relative;
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .filter {
    margin-top: 3rem;
  }
}
.filter.open {
  padding-bottom: 3.5rem;
  border-bottom: 1px solid var(--brandbook-main);
  transition: 0.5s ease;
}
.filter.open:before {
  content: "";
  width: 100%;
  height: 247px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(102, 102, 102, 0) 100%);
  opacity: 0.2;
  position: absolute;
  bottom: 0;
  left: 0;
}
.filter__line {
  display: flex;
  gap: 1.5rem;
  overflow: auto;
  flex-grow: 1;
}
.filter__control {
  display: flex;
  gap: 3rem;
  align-items: flex-start;
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .filter__control {
    flex-flow: column-reverse;
    gap: 1rem;
    margin-top: 2rem;
  }
}
.filter__btn {
  flex-shrink: 0;
}
.filter__open {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  gap: 2rem;
  width: 210px;
  position: relative;
  font-size: 1.125em;
  font-weight: 500;
  border-bottom: 1px solid var(--brandbook-main);
  flex-shrink: 0;
}
@media screen and (max-width: 1023px) {
  .filter__open {
    font-size: 1em;
  }
}
@media screen and (max-width: 767px) {
  .filter__open {
    width: 100%;
  }
}
.filter__open:after {
  content: "";
  width: 16px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.filter__open.open:after {
  transform: translateY(-50%) rotate(180deg);
}
.filter__list {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(5, minmax(auto, 210px));
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .filter__list {
    gap: 1rem;
  }
}
@media screen and (max-width: 1023px) {
  .filter__list {
    grid-template-columns: repeat(3, minmax(auto, 210px));
  }
}
@media screen and (max-width: 767px) {
  .filter__list {
    grid-template-columns: 1fr;
  }
}
.filter__all {
  display: none;
  position: relative;
}
.filter__check {
  display: block;
  margin-bottom: 1.2rem;
}
.filter__head {
  padding: 1.5rem 2rem;
  gap: 2rem;
  position: relative;
  font-size: 1.125em;
  font-weight: 500;
  cursor: pointer;
}
.filter__head:after {
  content: "";
  width: 16px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s ease;
}
.filter__hidden {
  display: none;
}
.filter__hidden:checked + .filter__txt:after {
  opacity: 1;
  visibility: visible;
}
.filter__txt {
  font-size: 1.125em;
  font-weight: 500;
  text-wrap: balance;
  cursor: pointer;
  position: relative;
  padding-left: 4rem;
}
@media screen and (max-width: 1200px) {
  .filter__txt {
    padding-left: 3rem;
    font-size: 1em;
  }
}
.filter__txt:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  width: 18px;
  height: 18px;
}
.filter__txt:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.filter__submit {
  margin: 0 auto;
  width: 157px;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
}
@media screen and (max-width: 1023px) {
  .filter__submit {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .filter__submit {
    position: relative;
  }
}
.filter__submit svg {
  flex-shrink: 0;
}
.filter__slider {
  display: flex;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 100px;
  background: var(--fon);
  box-sizing: border-box;
  position: relative;
  height: 75px;
}
.filter__group {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  background: var(--base-cristal-white);
  padding: 0.5rem 1rem;
  font-weight: 600;
}
.filter__group_start {
  left: 1rem;
}
.filter__group_end {
  right: 1rem;
}

.line {
  position: relative;
  width: 100%;
  height: 12px;
  background-color: #babdc2;
  bottom: 0;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
  position: absolute;
  background: var(--brandbook-main);
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
  background-color: var(--base-rich-black);
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: block;
  position: absolute;
}
.ui-slider-horizontal .ui-slider-handle:first-of-type {
  transform: translateX(-5px);
}
.ui-slider-horizontal .ui-slider-handle:last-of-type {
  transform: translateX(-7px);
}

.heading {
  margin-top: 14.7rem;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .heading {
    margin-top: 7rem;
  }
}
.heading__title {
  margin-top: 3.5rem;
  font-size: 4.5em;
  line-height: 1;
}
@media screen and (max-width: 1200px) {
  .heading__title {
    font-size: 3.75em;
  }
}
@media screen and (max-width: 767px) {
  .heading__title {
    font-size: 3.125em;
    margin-top: 2rem;
  }
}

.breadcrumb {
  opacity: 0.3;
}
.breadcrumb__link {
  font-size: 0.75em;
  color: var(--base-rich-black);
}
.breadcrumb__link:after {
  content: "/";
  margin: 0 1rem;
}
.breadcrumb__link:last-child:after {
  content: none;
}

.video-catalog {
  position: relative;
}
@media screen and (max-width: 900px) {
  .video-catalog__pic {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}
@media screen and (max-width: 767px) {
  .video-catalog__pic {
    height: 292px;
  }
}
.video-catalog__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.banner {
  position: relative;
}
.banner__video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
}
@media screen and (max-width: 1023px) {
  .banner__video {
    min-height: 860px;
  }
}
@media screen and (max-width: 767px) {
  .banner__video {
    min-height: 85vh;
  }
}
.banner__icon {
  margin: 10rem 0 12rem;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1200px) {
  .banner__icon {
    margin: 7rem 0;
  }
}
@media screen and (max-width: 1023px) {
  .banner__icon {
    margin-bottom: clamp(7rem, 30vh, 240px);
  }
}
@media screen and (max-width: 767px) {
  .banner__icon {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: clamp(2rem, 10vh, 240px);
  }
}
.banner__title {
  font-weight: 400;
  font-size: clamp(39px, 12vw, 190px);
  text-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  line-height: 0.85;
  position: relative;
  z-index: 3;
}
.banner__title span, .banner__title b {
  background: linear-gradient(90deg, #fff 63.02%, rgba(255, 255, 255, 0.26) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.banner__slide {
  position: relative;
  z-index: 1;
}
.banner__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 11rem;
  box-sizing: border-box;
  z-index: 2;
}
.banner .slick-arrow {
  position: absolute;
  bottom: 8rem;
  z-index: 3;
}
.banner .slick-prev {
  left: calc((100% - 1440px) / 2);
}
@media screen and (max-width: 1440px) {
  .banner .slick-prev {
    left: 2rem;
  }
}
.banner .slick-next {
  right: calc((100% - 1440px) / 2);
}
@media screen and (max-width: 1440px) {
  .banner .slick-next {
    right: 2rem;
  }
}
.banner .slick-slide {
  height: auto;
}
.banner .slick-dots {
  position: absolute;
  bottom: 9rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
@media screen and (max-width: 767px) {
  .banner .slick-dots {
    display: none !important;
  }
}
.banner .slick-dots button {
  font-weight: 500;
  color: var(--base-cristal-white);
  font-size: 1em;
}
.banner .slick-dots li {
  position: relative;
}
.banner .slick-dots li.slick-active:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -40%;
  background: var(--base-cristal-white);
  border-radius: 100%;
  z-index: 2;
  transition: 0.5s ease;
}

.busket {
  margin-top: 14.5rem;
  position: relative;
  z-index: 2;
  padding-bottom: 7rem;
}
@media screen and (max-width: 767px) {
  .busket {
    margin-top: 9rem;
  }
}
.busket__title {
  font-size: 4.5em;
  font-weight: 700;
  margin-bottom: 7rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1023px) {
  .busket__title {
    font-size: 3.5625em;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 1023px) {
  .busket__title {
    font-size: 1.5em;
    margin-bottom: 3rem;
  }
}
.busket__row {
  display: flex;
  gap: min(120px, 6vw);
  position: relative;
}
@media screen and (max-width: 1200px) {
  .busket__row {
    display: block;
  }
}
.busket__row:before {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--base-cristal-white);
  position: absolute;
  top: 110px;
}
@media screen and (max-width: 1200px) {
  .busket__row:before {
    top: 90px;
  }
}
.busket__left {
  max-width: 705px;
}
@media screen and (max-width: 1200px) {
  .busket__left {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .busket__left {
    margin-bottom: 7rem;
  }
}
.busket__count {
  font-size: 1.875em;
  font-weight: 500;
  margin-bottom: 10rem;
}
@media screen and (max-width: 1023px) {
  .busket__count {
    font-size: 1.5em;
    margin-bottom: 5rem;
  }
}
.busket__count span {
  color: var(--brandbook-more);
}
.busket__count b {
  font-size: 1.334375em;
  color: var(--brandbook-more);
  font-weight: 700;
}
@media screen and (max-width: 1500px) {
  .busket__count b {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .busket__count b {
    display: inline-block;
  }
}
.busket__products {
  display: flex;
  gap: 3.5rem;
}
@media screen and (max-width: 1023px) {
  .busket__products {
    flex-flow: column;
    gap: 6rem;
  }
}
.busket__products .prod-item {
  background: var(--base-cristal-white);
}
.busket__products .prod-item:hover .prod-item__numbers, .busket__products .prod-item:hover .prod-item__remove {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 767px) {
  .busket__products .prod-item .prod-item__numbers, .busket__products .prod-item .prod-item__remove {
    opacity: 1;
    visibility: visible;
  }
}
.busket__line {
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .busket__line {
    margin-bottom: 4rem;
  }
}
.busket__caption {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .busket__caption {
    font-size: 1.625em;
    margin-bottom: 3rem;
  }
}
.busket__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem 3.5rem;
}
@media screen and (max-width: 767px) {
  .busket__list {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
.busket__field {
  border: 1px solid #d9e8e8;
  border-radius: 16px;
  padding: 2rem;
  box-sizing: border-box;
  background: var(--base-cristal-white);
  font-weight: 500;
  font-size: 1em;
}
.busket__field::placeholder {
  color: #949393;
}
.busket__field_full {
  grid-column: 1/3;
}
@media screen and (max-width: 767px) {
  .busket__field_full {
    grid-column: auto;
  }
}
.busket__wrap {
  display: flex;
  gap: 3.5rem;
}
@media screen and (max-width: 767px) {
  .busket__wrap {
    flex-flow: column;
  }
}
.busket__hidden {
  display: none;
}
.busket__hidden:checked + .busket__box {
  border-color: var(--base-rich-black);
  color: var(--base-rich-black);
}
.busket__hidden:checked + .busket__box:after {
  opacity: 1;
  visibility: visible;
}
.busket__hidden:checked + .busket__txt:after {
  opacity: 1;
  visibility: visible;
}
.busket__box {
  border: 1px solid #bdbdbd;
  border-radius: 50px;
  padding: 1rem 2.4rem;
  box-sizing: border-box;
  font-size: 1.125em;
  font-weight: 600;
  color: #bdbdbd;
  position: relative;
  height: 65px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s ease;
}
.busket__box:before {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  width: 18px;
  height: 18px;
}
.busket__box:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.busket__sign {
  padding-left: 2.4rem;
  margin-top: 2.5rem;
  font-weight: 500;
  color: #949393;
}
.busket__pic {
  margin-top: 1rem;
  margin-left: 2.4rem;
}
.busket__control {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}
@media screen and (max-width: 767px) {
  .busket__control {
    flex-flow: column;
    align-items: center;
    gap: 2rem;
  }
}
.busket__btn {
  background: var(--brandbook-main);
  font-size: 1.125em;
  color: var(--base-cristal-white);
  border: none;
  width: min(412px, 30vw);
  height: 72px;
}
@media screen and (max-width: 767px) {
  .busket__btn {
    width: 100%;
  }
}
.busket__txt {
  width: 160px;
  padding-left: 40px;
  position: relative;
  font-size: 0.75em;
  text-wrap: balance;
  cursor: pointer;
}
.busket__txt a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.busket__txt:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  width: 18px;
  height: 18px;
}
.busket__txt:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}

.promo {
  display: flex;
  justify-content: space-between;
  gap: 3.5rem;
  margin-bottom: 9rem;
}
@media screen and (max-width: 767px) {
  .promo {
    flex-flow: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 4rem;
  }
}
.promo__field {
  border: 1px solid #d9e8e8;
  border-radius: 16px;
  padding: 2rem;
  box-sizing: border-box;
  background: var(--base-cristal-white);
  font-weight: 500;
  font-size: 1em;
}
@media screen and (max-width: 767px) {
  .promo__field {
    width: 100%;
  }
}
.promo__field::placeholder {
  color: #949393;
}
.promo__txt {
  width: 160px;
  padding-left: 40px;
  position: relative;
  font-size: 0.75em;
  text-wrap: balance;
  cursor: pointer;
}
.promo__txt a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.promo__txt:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  width: 18px;
  height: 18px;
}
.promo__txt:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.promo__btn {
  background: var(--brandbook-main);
  font-size: 1.125em;
  color: var(--base-cristal-white);
  border: none;
}
@media screen and (max-width: 767px) {
  .promo__btn {
    width: 100%;
  }
}
.promo__hidden {
  display: none;
}
.promo__hidden:checked + .busket__txt:after {
  opacity: 1;
  visibility: visible;
}

.busket-success {
  margin-top: 14.5rem;
  position: relative;
  z-index: 2;
  padding-bottom: 7rem;
}
@media screen and (max-width: 767px) {
  .busket-success {
    margin-top: 9rem;
  }
}
.busket-success__title {
  font-size: 4.5em;
  font-weight: 700;
  margin-bottom: 7rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1023px) {
  .busket-success__title {
    font-size: 3.375em;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .busket-success__title {
    font-size: 1.5em;
    margin-bottom: 3rem;
  }
}
.busket-success__txt {
  font-size: 1.875em;
  font-weight: 500;
  margin-bottom: 6rem;
}
@media screen and (max-width: 767px) {
  .busket-success__txt {
    font-size: 1.5em;
    margin-bottom: 4rem;
  }
}
.busket-success__control {
  display: flex;
  gap: 3.5rem;
}
@media screen and (max-width: 767px) {
  .busket-success__control {
    flex-flow: column;
    gap: 2rem;
  }
}
.busket-success__more {
  font-weight: 600;
  padding: 2.6rem 2rem;
  line-height: 1;
  text-align: center;
  font-size: 1.125em;
}
@media screen and (max-width: 767px) {
  .busket-success__more {
    width: 100%;
  }
}
.busket-success__cat {
  background: var(--brandbook-main);
  color: var(--base-cristal-white);
  border: none;
  font-weight: 600;
  line-height: 1;
  padding: 2.6rem 2.4rem;
  width: 185px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .busket-success__cat {
    width: 100%;
  }
}
.busket-success__pic {
  max-width: 100%;
}

.catalog-mini {
  position: relative;
  padding: 5rem 0;
}
.catalog-mini:before {
  content: "";
  width: 110vw;
  height: 65vw;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../icon/wave-1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 1023px) {
  .catalog-mini:before {
    width: 150vw;
    height: 100vh;
  }
}
.catalog-mini__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1100px) {
  .catalog-mini__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1023px) {
  .catalog-mini__list {
    gap: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .catalog-mini__list {
    grid-template-columns: 1fr;
  }
}
.catalog-mini__link {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 0.5s ease;
}
.catalog-mini__link:hover {
  background: var(--brandbook-main);
  color: var(--base-cristal-white);
}
.catalog-mini__link:hover .catalog-mini__icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(7%) hue-rotate(168deg) brightness(102%) contrast(106%);
}
.catalog-mini__icon {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  transition: 0.5s ease;
}
.catalog-mini__title {
  font-weight: 700;
  font-size: 2.5em;
  line-height: 0.8;
  margin-bottom: 1rem;
  display: block;
}
@media screen and (max-width: 1023px) {
  .catalog-mini__title {
    font-size: 1.875em;
  }
}
.catalog-mini__sign {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.45;
  display: block;
}

.catalog {
  position: relative;
  padding: 5rem 0;
}
.catalog__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1200px) {
  .catalog__container {
    gap: 2rem;
  }
}
@media screen and (max-width: 1080px) {
  .catalog__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .catalog__container {
    grid-template-columns: 1fr;
  }
}
.catalog__box {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-shrink: 0;
}
.catalog__icon {
  width: 55px;
  height: 55px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .catalog__icon {
    width: 40px;
    height: 40px;
    top: -10px;
  }
}
.catalog__title {
  font-weight: 700;
  font-size: 2.5em;
  line-height: 0.8;
  margin-bottom: 1rem;
  display: block;
  height: 72px;
}
@media screen and (max-width: 1200px) {
  .catalog__title {
    font-size: 1.875em;
  }
}
@media screen and (max-width: 767px) {
  .catalog__title {
    font-size: 1.5em;
    height: auto;
  }
}
.catalog__sign {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.45;
  display: block;
}
.catalog__list {
  margin-top: 2.5rem;
}
.catalog__item {
  margin-bottom: 2.5rem;
}
.catalog__link {
  font-weight: 500;
  font-size: 1.125em;
}
.catalog__link:hover {
  color: var(--brandbook-main);
}

.places {
  background: linear-gradient(135deg, #9fa6b5 0%, #2a2e35 100%);
  padding: 5rem 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .places {
    padding-top: 2rem;
  }
}
.places:before {
  content: "";
  width: 100vw;
  height: 65vw;
  position: absolute;
  bottom: -35%;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../icon/wave-4.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 1023px) {
  .places:before {
    width: 150vw;
    height: 100vw;
  }
}
@media screen and (max-width: 767px) {
  .places:before {
    bottom: 30%;
    background-size: 150%;
    height: 100%;
    background-position: 100%;
  }
}
.places__inner {
  position: relative;
  z-index: 2;
}
.places__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5rem;
}
@media screen and (max-width: 1100px) {
  .places__top {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1023px) {
  .places__top {
    gap: 3rem;
  }
}
.places__txt {
  font-family: var(--font-secondary);
  font-style: italic;
  font-weight: 700;
  font-size: min(238px, 15vw);
  line-height: 0.8;
  -webkit-text-stroke: 2px #d3caca;
  color: transparent;
  position: relative;
  flex-grow: 1;
}
@media screen and (max-width: 1023px) {
  .places__txt {
    font-size: 20vw;
  }
}
@media screen and (max-width: 767px) {
  .places__txt {
    padding-top: 60px;
  }
}
.places__txt:before {
  content: attr(data-sign);
  font-size: min(24px, 1.5vw);
  color: #d3caca;
  position: absolute;
  top: 0;
  left: 39%;
  font-weight: 300;
  font-family: var(--font-primary);
  word-spacing: 10px;
}
@media screen and (max-width: 1100px) {
  .places__txt:before {
    left: 28%;
  }
}
@media screen and (max-width: 1023px) {
  .places__txt:before {
    font-size: 16px;
    left: 38%;
  }
}
@media screen and (max-width: 767px) {
  .places__txt:before {
    display: flex;
    flex-flow: column;
    width: 165px;
  }
}
.places__vnts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 7rem;
}
@media screen and (max-width: 1100px) {
  .places__vnts {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .places__vnts {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.places__vnt {
  text-align: center;
}
.places__vnt:hover .places__icon {
  filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%);
}
.places__vnt:hover .places__sign {
  color: var(--brandbook-main);
}
.places__icon {
  width: 41px;
  height: 41px;
}
.places__sign {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.125em;
  color: #d3caca;
}
@media screen and (max-width: 1200px) {
  .places__sign {
    font-size: 1em;
  }
}
.places__caption {
  color: var(--base-cristal-white);
  font-size: 2.5em;
  font-weight: 700;
  line-height: 0.8;
  margin: 3.5rem 0;
}
@media screen and (max-width: 1023px) {
  .places__caption {
    font-size: 1.875em;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .places__caption {
    font-size: 1.625em;
  }
}
.places__shops {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.places__link {
  position: relative;
  width: calc(25% - 27px);
  padding-bottom: 15px;
}
@media screen and (max-width: 1023px) {
  .places__link {
    width: calc(50% - 19px);
  }
}
@media screen and (max-width: 767px) {
  .places__link {
    width: 100%;
  }
}
.places__link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 86px;
  height: 15px;
  background: url(../icon/icon-stars.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.places__link svg {
  width: 100%;
}
.places__link:hover .oz-bg {
  fill: #2358f3;
}
.places__link:hover .oz-txt, .places__link:hover .wb-text, .places__link:hover .ya-over, .places__link:hover .mm-txt {
  fill: var(--base-cristal-white);
}
.places__link:hover .wb-bg {
  fill: url(#wb-hover);
}
.places__link:hover .ya-bg {
  fill: #fa3f1d;
}
.places__link:hover .ya-letter {
  fill: #fa3f1d;
}
.places__link:hover .mm-bg {
  fill: #9553d1;
}

.footer {
  background: var(--base-rich-black);
  padding: 14rem 0 9rem;
}
@media screen and (max-width: 1023px) {
  .footer {
    padding: 5rem 0;
    position: relative;
    z-index: 3;
  }
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
@media screen and (max-width: 1023px) {
  .footer__inner {
    flex-wrap: wrap;
  }
}
.footer__nav {
  display: flex;
  gap: min(215px, 10vw);
}
@media screen and (max-width: 767px) {
  .footer__nav {
    display: block;
  }
}
.footer__home {
  margin-top: -2rem;
}
@media screen and (max-width: 1023px) {
  .footer__home {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .footer__home {
    margin-bottom: 0;
  }
}
.footer__list {
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer__list {
    margin-top: 7rem;
  }
}
.footer__list:before {
  content: attr(data-title);
  color: #4E5661;
  position: absolute;
  top: -45px;
  left: 0;
  font-weight: 500;
  font-size: 0.875em;
}
@media screen and (max-width: 767px) {
  .footer__list:before {
    top: -35px;
  }
}
.footer__item {
  margin-bottom: 2.5rem;
}
.footer__link {
  color: var(--base-cristal-white);
  font-weight: 500;
  transition: 0.5s ease;
}
.footer__link:hover {
  color: var(--brandbook-main);
}
.footer__contacts {
  position: relative;
  color: var(--base-cristal-white);
  max-width: 215px;
}
@media screen and (max-width: 767px) {
  .footer__contacts {
    margin-left: 10vw;
    margin-top: 30px;
  }
}
.footer__caption {
  color: #4E5661;
  position: absolute;
  top: -45px;
  left: 0;
  font-weight: 500;
  font-size: 0.875em;
}
@media screen and (max-width: 767px) {
  .footer__caption {
    left: -30px;
    top: -35px;
  }
}
.footer__sign {
  font-size: 0.75em;
  margin-bottom: 1rem;
}
.footer__phone {
  font-size: 1.75em;
  font-weight: 600;
  position: relative;
  gap: 1rem;
  margin-bottom: 2.5rem;
  display: block;
  transition: 0.5s ease;
}
.footer__phone svg {
  width: 26px;
  height: 26px;
  color: #4E5661;
  position: absolute;
  left: -35px;
  top: 5px;
}
.footer__phone:hover {
  color: var(--brandbook-main);
}
.footer__address {
  font-size: 0.875em;
  font-weight: 600;
  position: relative;
  gap: 1rem;
  margin-bottom: 2.5rem;
  display: block;
}
.footer__address svg {
  position: absolute;
  left: -30px;
  top: 5px;
  width: 14px;
  height: 16px;
  color: #4E5661;
}
@media screen and (max-width: 767px) {
  .footer__address svg {
    width: 18px;
    height: 18px;
  }
}
.footer__social {
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
}
.footer__soc-link {
  border-radius: 30px;
  backdrop-filter: blur(20px);
  background: linear-gradient(107.01deg, rgba(0, 0, 0, 0.41) 30.416%, rgba(0, 0, 0, 0.29) 101.259%);
  padding: 1.4rem;
  box-sizing: border-box;
  transition: 0.5s ease;
}
.footer__soc-link:hover {
  background: var(--buttons-white);
}
.footer__icon {
  width: 18px;
  height: 18px;
}

.copy {
  background: var(--base-rich-black);
  padding-bottom: 4.5rem;
  color: #4E5661;
  font-size: 0.875em;
  font-weight: 500;
}
.copy__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .copy__inner {
    flex-wrap: wrap;
    gap: 3rem;
  }
}
.copy__link img {
  margin-left: 1rem;
}
@media screen and (max-width: 767px) {
  .copy__pic {
    order: -1;
    width: 100%;
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
}
@media screen and (max-width: 767px) {
  .header {
    padding: 2rem 0;
  }
}
.header:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  backdrop-filter: blur(56px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(142deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.15) 100%);
  opacity: 0.8;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .header__inner {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 1023px) {
  .header__home {
    position: absolute;
    top: 10px;
    left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .header__home {
    left: clamp(30px, 50vw, 200px);
    top: 0;
  }
}
@media screen and (max-width: 1400px) {
  .header__pic {
    width: 180px;
  }
}
@media screen and (max-width: 1023px) {
  .header__pic {
    width: 135px;
  }
}
@media screen and (max-width: 767px) {
  .header__pic {
    width: 85px;
  }
}
.header__info {
  flex-grow: 1;
}
@media screen and (max-width: 767px) {
  .header__info {
    flex-grow: 0;
  }
}
.header__top {
  padding-block: 1.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .header__top {
    margin-left: 120px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .header__top {
    display: none;
  }
}
.header__top:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 1px;
  background: var(--base-cristal-white);
  opacity: 0.2;
}
.header__search {
  width: 50%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .header__search {
    width: 33%;
  }
}
.header__search svg {
  width: 15px;
  height: 15px;
  color: var(--base-cristal-white);
  flex-shrink: 0;
}
.header__favourites svg, .header__lk svg {
  transition: 0.5s ease;
}
.header__favourites svg:hover, .header__lk svg:hover {
  color: var(--brandbook-main);
}
.header__field {
  font-size: 1em;
  font-weight: 500;
  background: none;
  margin-left: 1rem;
  color: var(--base-cristal-white);
}
.header__field::placeholder {
  color: var(--base-cristal-white);
}
.header__contacts {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 5rem;
}
@media screen and (max-width: 1023px) {
  .header__contacts {
    gap: 1rem;
  }
}
.header__city {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}
.header__city svg {
  width: 18px;
  height: 18px;
  color: var(--base-cristal-white);
  opacity: 0.3;
}
.header__city-select {
  background: none;
  border: none;
  color: var(--base-cristal-white);
  font-size: 1em;
  font-weight: 500;
  font-family: var(--font-primary);
}
.header__phone {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--base-cristal-white);
  flex-shrink: 0;
  transition: 0.5s ease;
}
.header__phone:hover {
  color: var(--brandbook-main);
}
.header__phone svg {
  width: 18px;
  height: 18px;
  opacity: 0.3;
}
.header__bottom {
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding-right: 3rem;
  box-sizing: border-box;
}
@media screen and (max-width: 1400px) {
  .header__bottom {
    padding-right: 0;
  }
}
@media screen and (max-width: 1100px) {
  .header__bottom {
    justify-content: space-between;
  }
}
.header__control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
}
.header__control svg {
  color: var(--base-cristal-white);
  width: 18px;
  height: 18px;
}
.header__basket.elms {
  position: relative;
}
.header__basket.elms:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 100%;
  z-index: 1;
  background: var(--brandbook-more);
}
.header__basket svg {
  transition: 0.5s ease;
}
.header__basket svg:hover {
  color: var(--brandbook-main);
}
.header__burger {
  display: none;
}
@media screen and (max-width: 767px) {
  .header__burger {
    display: block;
  }
}
@media screen and (max-width: 700px) {
  .header__lk {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    display: none;
  }
}
.nav__list {
  display: flex;
}
.nav__item {
  margin-bottom: 0;
  position: relative;
}
@media screen and (max-width: 1100px) {
  .nav__item {
    display: none;
  }
}
.nav__item:hover .nav__dropdown {
  display: block;
}
@media screen and (max-width: 1100px) {
  .nav__item:nth-of-type(-n + 4) {
    display: block;
  }
}
.nav__link {
  font-size: 0.9375em;
  text-transform: uppercase;
  color: var(--base-cristal-white);
  font-weight: 600;
  padding: 1.75rem 3rem;
  box-sizing: border-box;
  line-height: 1;
  display: inline-block;
}
@media screen and (max-width: 1500px) {
  .nav__link {
    padding: 1.75rem;
  }
}
@media screen and (max-width: 1200px) {
  .nav__link {
    padding: 1.75rem 0.8rem;
  }
}
.nav__link:hover {
  background: rgba(2, 13, 30, 0.2);
}
.nav__dropdown {
  position: fixed;
  top: 11rem;
  left: 0;
  width: 100vw;
  background: var(--brandbook-main);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
  z-index: -2;
  padding-block: 3.2rem;
  box-sizing: border-box;
  display: none;
}
.nav__drop-box {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7rem;
}
.nav__level {
  display: flex;
  flex-flow: column;
  gap: 1.6rem;
}
.nav__banner {
  width: 334px;
  height: 190px;
  border-radius: 8px;
  object-fit: cover;
}
.nav__level-link {
  font-size: 1em;
  font-weight: 500;
  color: var(--base-cristal-white);
  display: inline-block;
  border-bottom: 1px solid transparent;
  line-height: 1.2;
}
.nav__level-link:hover {
  border-bottom-color: var(--base-cristal-white);
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(56px);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(142deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.15) 100%);
  width: calc(100% - 3rem);
  z-index: 9999;
  max-height: 100%;
  overflow: auto;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.mobile-nav.open {
  transform: none;
  opacity: 1;
  visibility: visible;
}
.mobile-nav__search {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2.2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.mobile-nav__search svg {
  width: 15px;
  height: 15px;
  color: var(--base-cristal-white);
  flex-shrink: 0;
}
.mobile-nav__field {
  font-size: 1em;
  font-weight: 500;
  background: none;
  color: var(--base-cristal-white);
}
.mobile-nav__field::placeholder {
  color: var(--base-cristal-white);
}
.mobile-nav__list {
  margin: 1rem 0;
}
.mobile-nav__item {
  margin: 0 2rem;
}
.mobile-nav__link {
  margin-bottom: 0;
  font-size: 0.9375em;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--base-cristal-white);
  padding: 1.9rem 1.4rem;
  line-height: 1;
  display: inline-block;
  transition: 0.5s ease;
}
.mobile-nav__link.open {
  background: rgba(2, 13, 30, 0.5);
}
.mobile-nav__dropdown {
  backdrop-filter: blur(56px);
  background: var(--brandbook-main);
  padding: 3rem 2.6rem;
  margin: 0 -2rem;
  display: none;
}
.mobile-nav__level-item {
  margin-bottom: 2rem;
}
.mobile-nav__level-item:last-of-type {
  margin-bottom: 0;
}
.mobile-nav__level-link {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5;
  color: var(--base-cristal-white);
}
.mobile-nav__level-link.active {
  text-decoration: underline;
  text-decoration-skip-ink: revert;
}
.mobile-nav__lk {
  padding: 3rem 2.4rem;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.875em;
  font-weight: 500;
  color: var(--base-cristal-white);
}
.mobile-nav__lk svg {
  width: 18px;
  height: 18px;
  color: var(--base-cristal-white);
}
.mobile-nav__city {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  margin: 3rem 2.4rem;
}
.mobile-nav__city svg {
  width: 18px;
  height: 18px;
  color: var(--base-cristal-white);
  opacity: 0.3;
}
.mobile-nav__city-select {
  background: none;
  border: none;
  color: var(--base-cristal-white);
  font-size: 1em;
  font-weight: 500;
  font-family: var(--font-primary);
}
.mobile-nav__phone {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--base-cristal-white);
  flex-shrink: 0;
  transition: 0.5s ease;
  margin: 3rem 2.4rem;
}
.mobile-nav__phone:hover {
  color: var(--brandbook-main);
}
.mobile-nav__phone svg {
  width: 18px;
  height: 18px;
  opacity: 0.3;
}
.mobile-nav__social {
  display: flex;
  padding-inline: 2.4rem;
  gap: 1.4rem;
  margin-bottom: 3rem;
}
.mobile-nav__soc-link {
  border-radius: 30px;
  backdrop-filter: blur(20px);
  background: linear-gradient(107.01deg, rgba(0, 0, 0, 0.41) 30.416%, rgba(0, 0, 0, 0.29) 101.259%);
  padding: 1.4rem;
  box-sizing: border-box;
  transition: 0.5s ease;
}
.mobile-nav__soc-link:hover {
  background: var(--buttons-white);
}
.mobile-nav__icon {
  width: 18px;
  height: 18px;
}
.mobile-nav__close {
  position: absolute;
  top: 2.3rem;
  right: 1.7rem;
}

.poster-type-one {
  position: relative;
}
.poster-type-one__pic {
  position: relative;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  height: 395px;
}
@media screen and (max-width: 767px) {
  .poster-type-one__pic {
    height: 480px;
  }
}
.poster-type-one__outer-pic {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  bottom: 0;
  right: 0;
  object-fit: contain;
  object-position: bottom;
}
@media screen and (max-width: 1023px) {
  .poster-type-one__outer-pic {
    width: 130%;
    object-position: bottom right;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-one__outer-pic {
    width: 200%;
    object-position: 100px bottom;
  }
}
.poster-type-one__content {
  position: absolute;
  top: 6rem;
  left: max(4rem, 15.125vw);
  z-index: 4;
}
@media screen and (max-width: 1023px) {
  .poster-type-one__content {
    left: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-one__content {
    left: 2rem;
    top: 3.5rem;
    width: calc(100% - 4rem);
  }
}
.poster-type-one__sign {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2.6rem;
}
.poster-type-one__sign span {
  font-weight: 500;
  font-size: 1.125em;
}
.poster-type-one__sign_white {
  color: var(--base-cristal-white);
}
.poster-type-one__icon {
  width: 24px;
  height: 24px;
}
.poster-type-one__title {
  font-size: 2.5em;
  color: var(--base-cristal-white);
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .poster-type-one__title {
    font-size: 1.875em;
  }
}
.poster-type-one__title i {
  font-family: var(--font-secondary);
}
.poster-type-one__title span {
  font-weight: 700;
  display: block;
}
@media screen and (max-width: 767px) {
  .poster-type-one__title span {
    font-size: 1.125em;
  }
}
.poster-type-one__btn {
  margin-top: max(2rem, 4.5vw);
}

.poster-type-two {
  position: relative;
  padding: 7rem 0 3.5rem;
  background-image: url(../img/poster-5.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1023px) {
  .poster-type-two {
    padding: 5rem 0 3.5rem;
    background-size: 200%;
    background-position: 70% top;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-two {
    background-size: 455%;
    background-position: 71% top;
  }
}
.poster-type-two:before {
  content: "";
  width: 100vw;
  height: 65vw;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-image: url(../icon/wave-2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.poster-type-two__inner {
  position: relative;
  z-index: 2;
}
.poster-type-two__title {
  margin: 3rem 0;
  font-size: 4.375em;
  line-height: 1;
  max-width: 650px;
  width: 100%;
  text-wrap: balance;
}
@media screen and (max-width: 1100px) {
  .poster-type-two__title {
    font-size: 3.125em;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-two__title {
    font-size: 2.5em;
  }
}
.poster-type-two__icon {
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .poster-type-two__icon {
    width: 100%;
  }
}
.poster-type-two__bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 1023px) {
  .poster-type-two__bottom {
    flex-wrap: wrap;
  }
}
.poster-type-two__pic {
  margin-bottom: 7rem;
}
@media screen and (max-width: 1400px) {
  .poster-type-two__pic {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .poster-type-two__pic {
    width: 70%;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-two__pic {
    width: 100%;
  }
}
.poster-type-two__subscribe {
  max-width: 605px;
  width: 50%;
}
@media screen and (max-width: 1023px) {
  .poster-type-two__subscribe {
    width: 100%;
    margin-right: 0;
    margin-left: auto;
  }
}
.poster-type-two__action {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.4);
  padding: 1.1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 2rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .poster-type-two__action {
    padding: 2rem 1.1rem;
    margin-bottom: 70px;
  }
}
.poster-type-two__field {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1;
  padding-left: 7rem;
  color: var(--base-cristal-white);
  background: none;
  flex-grow: 1;
}
.poster-type-two__field::placeholder {
  color: var(--base-cristal-white);
}
.poster-type-two__ic {
  position: absolute;
  left: 4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  color: var(--base-cristal-white);
}
.poster-type-two__submit {
  border-radius: 50px;
  background: rgb(130, 211, 26);
  padding: 1.6rem 2.4rem;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 1.125em;
  color: var(--base-cristal-white);
  line-height: 1;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .poster-type-two__submit {
    position: absolute;
    bottom: -60px;
    right: 0;
  }
}
.poster-type-two__submit:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #73b61c 0%, #558813 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.poster-type-two__submit:hover:before {
  opacity: 1;
  visibility: visible;
}
.poster-type-two__txt {
  margin-top: 3.5rem;
  font-size: 1.875em;
  text-align: right;
  font-weight: 500;
}
.poster-type-two__txt b {
  font-style: italic;
  font-family: var(--font-secondary);
}

.poster-type-three {
  position: relative;
  z-index: 2;
}
.poster-type-three__inner {
  position: relative;
}
@media screen and (max-width: 1023px) {
  .poster-type-three__inner {
    padding: 0 1.6rem;
  }
}
.poster-type-three__pic {
  width: 100%;
  object-fit: cover;
  height: 395px;
  border-radius: 8px;
}
@media screen and (max-width: 1023px) {
  .poster-type-three__pic {
    height: 420px;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-three__pic {
    height: 395px;
  }
}
.poster-type-three__content {
  position: absolute;
  top: 6rem;
  left: max(4rem, 6vw);
  z-index: 4;
}
@media screen and (max-width: 767px) {
  .poster-type-three__content {
    top: 4rem;
    left: 4rem;
    width: calc(100% - 8rem);
  }
}
.poster-type-three__sign {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.8rem;
}
.poster-type-three__sign span {
  font-weight: 500;
  font-size: 1.125em;
}
.poster-type-three__icon {
  width: 24px;
  height: 24px;
}
.poster-type-three__title {
  font-size: 2.5em;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .poster-type-three__title {
    font-size: 1.875em;
  }
}
.poster-type-three__title i {
  font-family: var(--font-secondary);
}
.poster-type-three__title span {
  font-weight: 700;
  display: block;
}
@media screen and (max-width: 767px) {
  .poster-type-three__title span {
    font-size: 1.1875em;
  }
}
.poster-type-three__btn {
  margin-top: max(2rem, 4.5vw);
}
@media screen and (max-width: 767px) {
  .poster-type-three__btn {
    border-color: var(--base-cristal-white);
    color: var(--base-cristal-white);
  }
}
.poster-type-three__btn_right {
  position: absolute;
  right: 9rem;
  bottom: 5rem;
  margin-top: 0;
}
@media screen and (max-width: 1023px) {
  .poster-type-three__btn_right {
    right: auto;
    left: 3.2rem;
  }
}

.poster-type-four {
  padding: 5rem 0;
  background-image: url(../img/poster-3.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin-bottom: 3rem;
}
.poster-type-four:before {
  content: "";
  width: 100vw;
  height: 65vw;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-image: url(../icon/wave-3.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.poster-type-four__inner {
  position: relative;
  z-index: 2;
}
.poster-type-four__title {
  font-size: 4.375em;
  line-height: 1;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1023px) {
  .poster-type-four__title {
    font-size: 3.4375em;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-four__title {
    font-size: 2.5em;
  }
}
.poster-type-four__sign {
  font-size: 1.875em;
  font-weight: 500;
  margin-bottom: 4.5rem;
}
@media screen and (max-width: 767px) {
  .poster-type-four__sign {
    font-size: 1.5em;
  }
}

.poster-type-five {
  padding: 5rem 0;
  background-image: url(../img/poster-3.png);
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  margin-bottom: 3rem;
}
.poster-type-five:before {
  content: "";
  width: 100vw;
  height: 65vw;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-image: url(../icon/wave-2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.poster-type-five__inner {
  position: relative;
  z-index: 2;
}
.poster-type-five__title {
  font-size: 4.375em;
  line-height: 1;
  margin-bottom: 3rem;
}
@media screen and (max-width: 900px) {
  .poster-type-five__title {
    font-size: 3.4375em;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-five__title {
    font-size: 2.5em;
  }
}
.poster-type-five__sign {
  font-size: 1.875em;
  font-weight: 500;
  margin-bottom: 4.5rem;
}
@media screen and (max-width: 900px) {
  .poster-type-five__sign {
    font-size: 1.625em;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-five__sign {
    font-size: 1.5em;
  }
}
.poster-type-five__subscribe {
  max-width: 605px;
  width: 50%;
  margin-right: 0;
  margin-left: auto;
  margin-top: 5rem;
}
@media screen and (max-width: 900px) {
  .poster-type-five__subscribe {
    width: 70%;
  }
}
@media screen and (max-width: 767px) {
  .poster-type-five__subscribe {
    width: 100%;
    margin-top: 8rem;
  }
}
.poster-type-five__action {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.4);
  padding: 1.1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 2rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .poster-type-five__action {
    padding: 2rem 1.1rem;
    margin-bottom: 70px;
  }
}
.poster-type-five__field {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1;
  padding-left: 7rem;
  color: var(--base-cristal-white);
  background: none;
  flex-grow: 1;
}
.poster-type-five__field::placeholder {
  color: var(--base-cristal-white);
}
.poster-type-five__ic {
  position: absolute;
  left: 4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  color: var(--base-cristal-white);
}
.poster-type-five__submit {
  border-radius: 50px;
  background: rgb(130, 211, 26);
  padding: 1.6rem 2.4rem;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 1.125em;
  color: var(--base-cristal-white);
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .poster-type-five__submit {
    position: absolute;
    bottom: -60px;
    right: 0;
  }
}
.poster-type-five__txt {
  margin-top: 3.5rem;
  font-size: 1.875em;
  text-align: right;
  font-weight: 500;
}
.poster-type-five__txt b {
  font-style: italic;
  font-family: var(--font-secondary);
}

.poster-prod {
  position: relative;
  margin-bottom: 3.5rem;
}
.poster-prod__pic {
  width: 100%;
  vertical-align: middle;
  height: 425px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .poster-prod__pic {
    object-position: -10vw;
    height: auto;
    object-position: 0;
  }
}
.poster-prod__content {
  position: absolute;
  top: 4rem;
  left: clamp(2rem, 10vw, 30rem);
}
@media screen and (max-width: 900px) {
  .poster-prod__content {
    left: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .poster-prod__content {
    width: calc(100% - 3.2rem);
    left: 1.6rem;
  }
}
.poster-prod__content_mod {
  width: 70vw;
  top: 10rem;
}
@media screen and (max-width: 1200px) {
  .poster-prod__content_mod {
    width: 80vw;
  }
}
@media screen and (max-width: 900px) {
  .poster-prod__content_mod {
    width: 90vw;
  }
}
.poster-prod__title {
  font-size: 4.375em;
  line-height: 1;
  margin-bottom: 2rem;
}
@media screen and (max-width: 900px) {
  .poster-prod__title {
    font-size: 3.4375em;
  }
}
@media screen and (max-width: 767px) {
  .poster-prod__title {
    font-size: min(40px, 12vw);
  }
}
.poster-prod__title_right {
  position: absolute;
  right: 0;
  text-align: right;
}
@media screen and (max-width: 900px) {
  .poster-prod__title_right {
    width: 40%;
    font-size: 3.125em;
  }
}
@media screen and (max-width: 767px) {
  .poster-prod__title_right {
    right: 1.6rem;
    width: 70%;
    font-size: min(40px, 12vw);
  }
}
.poster-prod__title b {
  font-style: italic;
  font-family: var(--font-secondary);
}
.poster-prod__sign {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 2rem;
}
@media screen and (max-width: 900px) {
  .poster-prod__sign {
    font-size: 1.875em;
  }
}
@media screen and (max-width: 767px) {
  .poster-prod__sign {
    font-size: min(30px, 6vw);
  }
}
.poster-prod__sign b {
  font-style: italic;
  font-family: var(--font-secondary);
}
@media screen and (max-width: 767px) {
  .poster-prod__list {
    display: none;
  }
}
.poster-prod__list li {
  padding-left: 3.6rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.5;
  background-image: url(../icon/icon-check.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 16px;
}

.catalog-type {
  position: relative;
  z-index: 3;
}
.catalog-type__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3.5rem;
  height: 711px;
  margin: 4rem 0;
}
@media screen and (max-width: 1500px) {
  .catalog-type__list {
    transform: scale(0.9) translateX(-5%);
    margin: 0;
  }
}
@media screen and (max-width: 1360px) {
  .catalog-type__list {
    transform: scale(0.8) translateX(-13%);
  }
}
@media screen and (max-width: 1100px) {
  .catalog-type__list {
    transform: none;
    flex-direction: row;
    height: auto;
    margin: 4rem 0;
  }
}
.catalog-type__item {
  position: relative;
  max-height: 100%;
}
@media screen and (max-width: 767px) {
  .catalog-type__item {
    width: 100%;
  }
}
.catalog-type__item:hover .catalog-type__title {
  opacity: 1;
}
.catalog-type__pic {
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .catalog-type__pic {
    width: 100%;
  }
}
.catalog-type__title {
  position: absolute;
  bottom: 0;
  font-weight: 400;
  font-size: max(68px, 90%);
  line-height: 0.7;
  z-index: 3;
  background: linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.34));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  text-transform: uppercase;
  left: 0;
  padding: 3.5rem;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.75s ease;
}
@media screen and (max-width: 767px) {
  .catalog-type__title {
    font-size: 1.75em;
    padding: 2rem;
  }
}
.catalog-type__title b {
  text-transform: lowercase;
  display: block;
  font-size: 1.178125em;
}

.banner-sales {
  margin-top: 11rem;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .banner-sales {
    margin-top: 6.2rem;
  }
}
.banner-sales__slide {
  position: relative;
}
.banner-sales__inner {
  position: absolute;
  top: 0;
  left: calc((100% - 1400px) / 2);
  padding-top: 3rem;
}
@media screen and (max-width: 1500px) {
  .banner-sales__inner {
    left: 6rem;
  }
}
@media screen and (max-width: 767px) {
  .banner-sales__inner {
    left: 1.6rem;
    width: calc(100% - 3.2rem);
  }
}
.banner-sales__title {
  font-size: 4.375em;
  line-height: 1;
  margin-bottom: 3.5rem;
}
@media screen and (max-width: 900px) {
  .banner-sales__title {
    font-size: 3.4375em;
  }
}
@media screen and (max-width: 767px) {
  .banner-sales__title {
    font-size: 2.5em;
    margin-bottom: 3rem;
  }
}
.banner-sales__sign {
  font-size: 1.875em;
  font-weight: 500;
  max-width: 750px;
}
@media screen and (max-width: 900px) {
  .banner-sales__sign {
    font-size: 1.625em;
  }
}
@media screen and (max-width: 767px) {
  .banner-sales__sign {
    font-size: 1.25em;
  }
}
.banner-sales__sign b {
  font-style: italic;
  font-family: var(--font-secondary);
}
.banner-sales__sign span {
  display: inline-block;
  font-weight: 700;
  color: var(--brandbook-main);
  padding: 0 0.5rem;
  background: var(--base-rich-black);
}
@media screen and (max-width: 900px) {
  .banner-sales__pic {
    height: 220px;
    object-fit: cover;
    object-position: center;
  }
}
@media screen and (max-width: 767px) {
  .banner-sales__pic {
    height: 291px;
    object-position: right;
  }
}
.banner-sales .slick-slide {
  height: auto;
}
.banner-sales .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
.banner-sales .slick-prev {
  display: none !important;
}
.banner-sales .slick-next {
  right: calc((100% - 1440px) / 2);
}
.banner-sales .slick-dots {
  position: absolute;
  bottom: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
@media screen and (max-width: 900px) {
  .banner-sales .slick-dots {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .banner-sales .slick-dots {
    display: flex !important;
    bottom: 2rem;
  }
}
.banner-sales .slick-dots button {
  font-weight: 500;
  color: var(--base-cristal-white);
  font-size: 1em;
}
.banner-sales .slick-dots li {
  position: relative;
}
.banner-sales .slick-dots li.slick-active:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -40%;
  background: var(--base-cristal-white);
  border-radius: 100%;
  z-index: 2;
  transition: 0.5s ease;
}
.banner-sales + .heading {
  margin-top: 3.5rem;
}

.products-list {
  padding-block: 6rem;
  position: relative;
  z-index: 9;
}
.products-list__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.5rem;
}
@media screen and (max-width: 900px) {
  .products-list__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .products-list__list {
    grid-template-columns: 1fr;
  }
}

.prod-item {
  border-radius: 8px;
  transition: 0.5s ease;
  position: relative;
}
@media screen and (max-width: 767px) {
  .prod-item {
    box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.2);
    background: var(--base-cristal-white);
  }
}
.prod-item:hover {
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.2);
  background: var(--base-cristal-white);
}
.prod-item:hover .products-list__control {
  opacity: 1;
}
.prod-item__top {
  position: relative;
}
.prod-item__pic {
  width: 100%;
  border-radius: 8px;
  vertical-align: middle;
}
.prod-item__control {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 2rem;
  opacity: 0;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .prod-item__control {
    opacity: 1;
  }
}
.prod-item__btn {
  padding: 1.4rem;
  border-radius: 50px;
  background: rgb(130, 211, 26);
  color: var(--base-cristal-white);
}
.prod-item__btn svg {
  width: 18px;
  height: 18px;
}
.prod-item__favorite {
  color: #E2E9F1;
}
.prod-item__favorite svg {
  width: 24px;
  height: 24px;
}
.prod-item__bottom {
  padding: 0.8rem 1.6rem 1rem;
  box-sizing: border-box;
}
.prod-item__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.prod-item__state {
  font-size: 0.75em;
  text-transform: lowercase;
  color: #82D31A;
}
.prod-item__foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.prod-item__title {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.4;
}
.prod-item__prices {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  flex-shrink: 0;
}
.prod-item__old {
  font-size: 0.875em;
  color: #989090;
}
.prod-item__old::after {
  content: attr(data-sale);
  color: #FF0000;
}
.prod-item__new {
  font-weight: 500;
  font-size: 1.25em;
}
.prod-item__numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
  background: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 767px) {
  .prod-item__numbers {
    height: auto;
    bottom: 0;
    top: auto;
    padding-block: 1rem;
  }
}
.prod-item__bt {
  background: var(--brandbook-main);
  width: 62px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25em;
  text-align: center;
  color: var(--base-cristal-white);
  line-height: 1;
  z-index: 1;
  position: relative;
  transition: 0.5s ease;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .prod-item__bt {
    height: 52px;
    width: 52px;
  }
}
.prod-item__bt:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #73b61c 0%, #558813 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.prod-item__bt:hover:before {
  opacity: 1;
  visibility: visible;
}
.prod-item__numb {
  text-align: center;
  width: 45px;
  font-size: 2em;
  padding: 0;
  background: none;
  -moz-appearance: textfield;
}
.prod-item__numb::-webkit-outer-spin-button, .prod-item__numb::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.prod-item__remove {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.125em;
  font-weight: 500;
  color: var(--brandbook-main);
  opacity: 0;
  transition: 0.5s ease;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
}

.product {
  position: relative;
}
.product .heading {
  margin-top: 3.5rem;
  margin-bottom: 6rem;
}
.product .heading__title {
  font-size: 3.875em;
}
@media screen and (max-width: 1200px) {
  .product .heading__title {
    font-size: 2.9375em;
  }
}
@media screen and (max-width: 767px) {
  .product .heading__title {
    font-size: 1.5em;
    margin-bottom: 3.4rem;
  }
}
.product__slider-nav {
  margin-bottom: -349px;
}
@media screen and (max-width: 900px) {
  .product__slider-nav {
    display: none;
  }
}
.product__slider-nav .slick-list {
  padding-left: calc((100% - 1440px) / 2);
}
.product__slider-nav .slick-slide {
  width: 293px;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .product__slider-nav .slick-slide {
    width: 200px;
  }
}
.product__slider-nav .slick-current {
  width: 720px;
  height: 293px;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .product__slider-nav .slick-current {
    width: 50vw;
  }
}
.product__slider-nav .slick-current .product__pic {
  opacity: 0;
}
.product__slider-main {
  position: relative;
}
.product__slider-main .slick-slide {
  height: auto;
}
.product__slider-main .slick-arrow {
  position: absolute;
  bottom: 13rem;
  z-index: 3;
  border-color: rgba(188, 190, 195, 0.3);
}
.product__slider-main .slick-arrow:hover {
  border-color: var(--brandbook-main);
}
.product__slider-main .slick-arrow:hover img {
  filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%);
}
.product__slider-main .slick-prev {
  left: 1.8rem;
}
.product__slider-main .slick-next {
  right: 1.8rem;
}
.product__slider-main .slick-dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}
.product__slider-main .slick-dots button {
  font-weight: 500;
  color: #b7b7b7;
  font-size: 1em;
}
.product__slider-main .slick-dots li {
  position: relative;
}
.product__slider-main .slick-dots li.slick-active:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -40%;
  background: #b7b7b7;
  border-radius: 100%;
  z-index: 2;
  transition: 0.5s ease;
}
.product__pic {
  width: 100%;
  max-height: 100%;
}
.product__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5.6rem;
  gap: 2rem;
}
@media screen and (max-width: 900px) {
  .product__inner {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .product__inner {
    margin-top: 3.4rem;
  }
}
.product__slider-main {
  width: 50%;
  max-width: 720px;
}
@media screen and (max-width: 900px) {
  .product__slider-main {
    width: 100%;
  }
}
.product__info {
  max-width: 601px;
  width: 50%;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 900px) {
  .product__info {
    width: 100%;
    max-width: 100%;
  }
}
.product__top, .product__middle, .product__foot {
  display: flex;
  justify-content: space-between;
  margin-top: 7rem;
}
@media screen and (max-width: 1500px) {
  .product__top, .product__middle, .product__foot {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 1100px) {
  .product__top, .product__middle, .product__foot {
    margin-top: 1rem;
    align-items: center;
  }
}
@media screen and (max-width: 900px) {
  .product__top, .product__middle, .product__foot {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .product__top, .product__middle, .product__foot {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .product__top, .product__middle, .product__foot {
    flex-flow: column;
    gap: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .product__foot {
    align-items: flex-end;
  }
}
@media screen and (max-width: 767px) {
  .product__left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .product__right {
    width: 100%;
    text-align: right;
  }
}
.product__art {
  font-size: 1.125em;
  line-height: 156%;
  color: #949393;
  margin-bottom: 2rem;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .product__art {
    margin-bottom: 0;
  }
}
.product__holder {
  display: flex;
  align-items: center;
  gap: 5rem;
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .product__holder {
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 0;
  }
}
.product__state {
  font-weight: 500;
  font-size: 1.125em;
  line-height: 156%;
}
.product__state_green {
  color: var(--brandbook-main);
}
.product__favorite {
  color: var(--brandbook-more);
  opacity: 0.8;
}
.product__favorite svg {
  width: 24px;
  height: 24px;
}
.product__old {
  font-size: 1.3125em;
  line-height: 130%;
  color: #949393;
  text-align: right;
}
.product__old:after {
  content: attr(data-sale);
  font-weight: 600;
  color: var(--brandbook-more);
  margin-left: 2.5rem;
}
.product__new {
  font-weight: 500;
  font-size: 3.75em;
  line-height: 100%;
  text-align: center;
  color: var(--base-rich-black);
}
@media screen and (max-width: 1100px) {
  .product__new {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 900px) {
  .product__new {
    font-size: 3.75em;
  }
}
@media screen and (max-width: 767px) {
  .product__new {
    font-size: 3.125em;
    text-align: right;
  }
}
.product__numbers {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.product__bt {
  background: var(--brandbook-main);
  width: 62px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25em;
  text-align: center;
  color: var(--base-cristal-white);
  line-height: 1;
  z-index: 1;
  position: relative;
  transition: 0.5s ease;
  cursor: pointer;
}
.product__bt:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #73b61c 0%, #558813 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.product__bt:hover:before {
  opacity: 1;
  visibility: visible;
}
.product__numb {
  text-align: center;
  width: 45px;
  font-size: 2em;
  padding: 0;
  background: none;
  -moz-appearance: textfield;
}
.product__numb::-webkit-outer-spin-button, .product__numb::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.product__btn {
  border-radius: 50px;
  padding: 28px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(334px, 55%);
  gap: 2rem;
  color: var(--base-cristal-white);
  font-weight: 600;
  font-size: 1.125em;
  background: var(--brandbook-main);
  position: relative;
  z-index: 1;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .product__btn {
    width: 100%;
  }
}
.product__btn svg {
  width: 18px;
  height: 18px;
}
.product__btn:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #73b61c 0%, #558813 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}
.product__btn:hover:before {
  opacity: 1;
  visibility: visible;
}
.product__colors {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}
@media screen and (max-width: 1100px) {
  .product__colors {
    gap: 0.5rem;
  }
}
.product__hidden {
  display: none;
}
.product__hidden:checked + span::before {
  opacity: 1;
  visibility: visible;
}
.product__color-type {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  display: block;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 1100px) {
  .product__color-type {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 767px) {
  .product__color-type {
    width: 52px;
    height: 52px;
  }
}
.product__color-type.blue {
  background: linear-gradient(180deg, #98d4ff 0%, #000 100%);
}
.product__color-type.green {
  background: linear-gradient(180deg, #82d31a 0%, #000 100%);
}
.product__color-type.pink {
  background: linear-gradient(180deg, #ff98dc 0%, #000 100%);
}
.product__color-type.orange {
  background: linear-gradient(180deg, #ffe298 0%, #000 100%);
}
.product__color-type.red {
  background: linear-gradient(180deg, #ff9898 0%, #000 100%);
}
.product__color-type:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 3px solid var(--brandbook-more);
  z-index: 2;
  box-sizing: border-box;
  transition: 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.product-info {
  position: relative;
  z-index: 3;
  margin-top: 3rem;
  padding-bottom: 9rem;
}
.product-info__shops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 900px) {
  .product-info__shops {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .product-info__link {
    width: 100%;
  }
}
.product-info__link svg {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .product-info__link svg {
    height: 96px;
  }
}
.product-info__link:hover .oz-bg {
  fill: #2358f3;
}
.product-info__link:hover .oz-txt, .product-info__link:hover .wb-text, .product-info__link:hover .ya-over, .product-info__link:hover .mm-txt {
  fill: var(--base-cristal-white);
}
.product-info__link:hover .wb-bg {
  fill: url(#wb-hover);
}
.product-info__link:hover .ya-bg {
  fill: #fa3f1d;
}
.product-info__link:hover .ya-letter {
  fill: #fa3f1d;
}
.product-info__link:hover .mm-bg {
  fill: #9553d1;
}
.product-info__text-box {
  padding: 5rem 0 3.5rem;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 156%;
}
@media screen and (max-width: 767px) {
  .product-info__text-box {
    padding: 3rem 0;
  }
}
.product-info__text-box p {
  margin-bottom: 2rem;
}
.product-info__text-box p:last-of-type {
  margin-bottom: 0;
}
.product-info__open-tab {
  display: inline-flex;
  align-items: center;
  padding: 1.5rem 5rem 1.5rem 2rem;
  gap: 2rem;
  position: relative;
  font-size: 1.125em;
  font-weight: 500;
  border-bottom: 1px solid var(--brandbook-main);
  flex-shrink: 0;
  margin-top: 5rem;
  cursor: pointer;
  box-sizing: border-box;
}
@media screen and (max-width: 1023px) {
  .product-info__open-tab {
    font-size: 1em;
  }
}
@media screen and (max-width: 767px) {
  .product-info__open-tab {
    width: 100%;
  }
}
.product-info__open-tab:after {
  content: "";
  width: 16px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.125 1.55957L8 7.5L1.875 1.55957' stroke='%23464646' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.product-info__open-tab.open:after {
  transform: translateY(-50%);
}
.product-info__btn {
  margin-top: 3.5rem;
}
@media screen and (max-width: 767px) {
  .product-info__btn {
    margin: 3rem auto 0;
    display: flex;
    max-width: 282px;
  }
}

.product-slider {
  position: relative;
  z-index: 3;
  margin: 9rem 0;
}
@media screen and (max-width: 900px) {
  .product-slider {
    margin: 5rem 0;
  }
}
.product-slider__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3.5rem;
  margin-bottom: 4rem;
}
.product-slider__title {
  font-size: 4.5em;
  line-height: 1;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .product-slider__title {
    font-size: 3.5625em;
  }
}
@media screen and (max-width: 767px) {
  .product-slider__title {
    font-size: 1.5em;
  }
}
.product-slider__control {
  flex-shrink: 0;
}
.product-slider .slick-arrow {
  border-color: rgba(185, 187, 193, 0.3);
  margin-left: 2rem;
}
.product-slider .slick-arrow:hover {
  border-color: var(--brandbook-main);
}
.product-slider .slick-arrow:hover img {
  filter: brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(521%) hue-rotate(44deg) brightness(98%) contrast(86%);
}
.product-slider .slick-slide {
  margin-right: 3.5rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .product-slider .slick-slide {
    margin-right: 0;
  }
}
.product-slider .slick-dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
.product-slider .slick-dots button {
  font-weight: 500;
  color: #b7b7b7;
  font-size: 0.75em;
}
.product-slider .slick-dots li {
  position: relative;
}
.product-slider .slick-dots li.slick-active:before {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -40%;
  background: #b7b7b7;
  border-radius: 100%;
  z-index: 2;
  transition: 0.5s ease;
}

.product-buy {
  position: relative;
  z-index: 3;
  padding-block: 5rem 7rem;
  background: #e5e5e5;
}
@media screen and (max-width: 767px) {
  .product-buy {
    padding-block: 3rem;
  }
}
.product-buy__title {
  font-size: 4.5em;
  line-height: 1;
  margin-bottom: 4rem;
  font-weight: 700;
}
@media screen and (max-width: 900px) {
  .product-buy__title {
    font-size: 3.5625em;
  }
}
@media screen and (max-width: 767px) {
  .product-buy__title {
    font-size: 1.5em;
  }
}
.product-buy__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.5rem;
}
@media screen and (max-width: 900px) {
  .product-buy__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .product-buy__list {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .product-buy__list .prod-item:nth-of-type(3), .product-buy__list .prod-item:nth-of-type(4) {
    display: none;
  }
}
.product-buy__control {
  text-align: center;
  margin-top: 3rem;
  display: none;
}
@media screen and (max-width: 767px) {
  .product-buy__control {
    display: block;
  }
}