.busket
  margin-top: 14.5rem
  position: relative
  z-index: 2
  padding-bottom: 7rem
  +screen('max', 767)
    margin-top: 9rem
  &__title
    +fs(72px)
    font-weight: 700
    margin-bottom: 7rem
    margin-top: 2rem
    +screen('max', 1023)
      +fs(57px)
      margin-bottom: 5rem
    +screen('max', 1023)
      +fs(24px)
      margin-bottom: 3rem
  &__row
    display: flex
    gap: min(120px, 6vw)
    position: relative
    +screen('max', 1200)
      display: block
    &:before
      content: ''
      width: 100%
      height: 1px
      background: var(--base-cristal-white)
      position: absolute
      top: 110px
      +screen('max', 1200)
        top: 90px
  &__left
    max-width: 705px
    +screen('max', 1200)
      margin-bottom: 5rem
    +screen('max', 767)
      margin-bottom: 7rem
  &__count
    +fs(30px)
    font-weight: 500
    margin-bottom: 10rem
    +screen('max', 1023)
      +fs(24px)
      margin-bottom: 5rem
    span
      color: var(--brandbook-more)
    b
      +fs(21.35px)
      color: var(--brandbook-more)
      font-weight: 700
      +screen('max', 1500)
        display: block
      +screen('max', 1200)
        display: inline-block
  &__products
    display: flex
    gap: 3.5rem
    +screen('max', 1023)
      flex-flow: column
      gap: 6rem
    .prod-item
      background: var(--base-cristal-white)
      &:hover
        .prod-item__numbers, .prod-item__remove
          opacity: 1
          visibility: visible
      .prod-item__numbers, .prod-item__remove
        +screen('max', 767)
          opacity: 1
          visibility: visible
  &__line
    margin-bottom: 5rem
    +screen('max', 767)
      margin-bottom: 4rem
  &__caption
    +fs(40px)
    font-weight: 700
    margin-bottom: 5rem
    +screen('max', 767)
      +fs(26px)
      margin-bottom: 3rem
  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 2.5rem 3.5rem
    +screen('max', 767)
      grid-template-columns: 1fr
      gap: 2rem
  &__field
    border: 1px solid #d9e8e8
    border-radius: 16px
    padding: 2rem
    box-sizing: border-box
    background: var(--base-cristal-white)
    font-weight: 500
    +fs(16px)
    &::placeholder
      color: #949393
    &_full
      grid-column: 1/3
      +screen('max', 767)
        grid-column: auto
  &__wrap
    display: flex
    gap: 3.5rem
    +screen('max', 767)
      flex-flow: column
  &__hidden
    display: none
    &:checked +
      .busket__box
        border-color: var(--base-rich-black)
        color: var(--base-rich-black)
        &:after
          opacity: 1
          visibility: visible
      .busket__txt
        &:after
          opacity: 1
          visibility: visible
  &__box
    border: 1px solid #bdbdbd
    border-radius: 50px
    padding: 1rem 2.4rem
    box-sizing: border-box
    +fs(18px)
    font-weight: 600
    color: #bdbdbd
    position: relative
    height: 65px
    display: flex
    align-items: center
    cursor: pointer
    transition: .5s ease
    &:before
      content: ''
      position: absolute
      right: 20px
      top: 50%
      transform: translateY(-50%)
      border-radius: 4px
      border: 1px solid #bdbdbd
      width: 18px
      height: 18px
    &:after
      content: ''
      position: absolute
      right: 20px
      top: 50%
      transform: translateY(-50%)
      border-radius: 4px
      width: 20px
      height: 20px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-size: contain
      opacity: 0
      visibility: hidden
      transition: .5s ease
  &__sign
    padding-left: 2.4rem
    margin-top: 2.5rem
    font-weight: 500
    color: #949393
  &__pic
    margin-top: 1rem
    margin-left: 2.4rem
  &__control
    display: flex
    justify-content: space-between
    gap: 5rem
    +screen('max', 767)
      flex-flow: column
      align-items: center
      gap: 2rem
  &__btn
    background: var(--brandbook-main)
    +fs(18px)
    color: var(--base-cristal-white)
    border: none
    width: min(412px, 30vw)
    height: 72px
    +screen('max', 767)
      width: 100%
  &__txt
    width: 160px
    padding-left: 40px
    position: relative
    +fs(12px)
    text-wrap: balance
    cursor: pointer
    a
      text-decoration: underline
      text-decoration-skip-ink: none
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      border: 1px solid #bdbdbd
      width: 18px
      height: 18px
    &:after
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      width: 20px
      height: 20px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-size: contain
      opacity: 0
      visibility: hidden
      transition: .5s ease

.promo
  display: flex
  justify-content: space-between
  gap: 3.5rem
  margin-bottom: 9rem
  +screen('max', 767)
    flex-flow: column
    align-items: center
    gap: 2rem
    margin-bottom: 4rem
  &__field
    border: 1px solid #d9e8e8
    border-radius: 16px
    padding: 2rem
    box-sizing: border-box
    background: var(--base-cristal-white)
    font-weight: 500
    +fs(16px)
    +screen('max', 767)
      width: 100%
    &::placeholder
      color: #949393
  &__txt
    width: 160px
    padding-left: 40px
    position: relative
    +fs(12px)
    text-wrap: balance
    cursor: pointer
    a
      text-decoration: underline
      text-decoration-skip-ink: none
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      border: 1px solid #bdbdbd
      width: 18px
      height: 18px
    &:after
      content: ''
      position: absolute
      left: 0
      top: 0
      border-radius: 4px
      width: 20px
      height: 20px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Crect width='18' height='18' fill='%2373B61C' rx='4'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m12 6.75-4 4.5L6 9'/%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-size: contain
      opacity: 0
      visibility: hidden
      transition: .5s ease
  &__btn
    background: var(--brandbook-main)
    +fs(18px)
    color: var(--base-cristal-white)
    border: none
    +screen('max', 767)
      width: 100%
  &__hidden
    display: none
    &:checked +
      .busket__txt
        &:after
          opacity: 1
          visibility: visible

.busket-success
  margin-top: 14.5rem
  position: relative
  z-index: 2
  padding-bottom: 7rem
  +screen('max', 767)
    margin-top: 9rem
  &__title
    +fs(72px)
    font-weight: 700
    margin-bottom: 7rem
    margin-top: 2rem
    +screen('max', 1023)
      +fs(54px)
      margin-bottom: 5rem
    +screen('max', 767)
      +fs(24px)
      margin-bottom: 3rem
  &__txt
    +fs(30px)
    font-weight: 500
    margin-bottom: 6rem
    +screen('max', 767)
      +fs(24px)
      margin-bottom: 4rem
  &__control
    display: flex
    gap: 3.5rem
    +screen('max', 767)
      flex-flow: column
      gap: 2rem
  &__more
    font-weight: 600
    padding: 2.6rem 2rem
    line-height: 1
    text-align: center
    +fs(18px)
    +screen('max', 767)
      width: 100%
  &__cat
    background: var(--brandbook-main)
    color: var(--base-cristal-white)
    border: none
    font-weight: 600
    line-height: 1
    padding: 2.6rem 2.4rem
    width: 185px
    text-align: center
    +screen('max', 767)
      width: 100%
  &__pic
    max-width: 100%