@font-face
  font-family: 'Golos Text'
  src: url('../font/GolosText-Regular.woff2') format("woff2"), url('../font/GolosText-Regular.woff') format("woff"), url('../font/GolosText-Regular.ttf') format("truetype")
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Golos Text'
  src: url('../font/GolosText-DemiBold.woff2') format("woff2"), url('../font/GolosText-DemiBold.woff') format("woff"), url('../font/GolosText-DemiBold.ttf') format("truetype")
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Golos Text'
  src: url('../font/GolosText-Bold.woff2') format("woff2"), url('../font/GolosText-Bold.woff') format("woff"), url('../font/GolosText-Bold.ttf') format("truetype")
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Golos Text VF'
  src: url('../font/GolosTextVF-Regular.woff2') format("woff2"), url('../font/GolosTextVF-Regular.woff') format("woff"), url('../font/GolosTextVF-Regular.ttf') format("truetype")
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Golos Text'
  src: url('../font/GolosText-Medium.woff2') format("woff2"), url('../font/GolosText-Medium.woff') format("woff"), url('../font/GolosText-Medium.ttf') format("truetype")
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Golos Text'
  src: url('../font/GolosText-Black.woff2') format("woff2"), url('../font/GolosText-Black.woff') format("woff"), url('../font/GolosText-Black.ttf') format("truetype")
  font-weight: 900
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Enter Sansman'
  src: url('../font/EnterSansmanBold.woff2') format("woff2"), url('../font/EnterSansmanBold.woff') format("woff"), url('../font/EnterSansmanBold.ttf') format("truetype")
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Enter Sansman'
  src: url('../font/EnterSansmanBoldItalic.woff2') format("woff2"), url('../font/EnterSansmanBoldItalic.woff') format("woff"), url('../font/EnterSansmanBoldItalic.ttf') format("truetype")
  font-weight: bold
  font-style: italic
  font-display: swap
